import UUHukumContent from "../components/layouts/ncb_interpol/UUHukumContent";

const UUHukum = () => {
  return (
    <div>
      <UUHukumContent />
    </div>
  );
};

export default UUHukum;
