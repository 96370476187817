import ProfilMisiIntContent from "../components/layouts/biromisi/ProfilMisiIntContent";

const ProfilMisiInt = () => {
  return (
    <div>
      <ProfilMisiIntContent />
    </div>
  );
};

export default ProfilMisiInt;
