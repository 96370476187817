import EventContent from "../components/layouts/event/EventContent";

const Event = () => {
  return (
    <div>
      <EventContent />
    </div>
  );
};

export default Event;
