import { Link } from "react-router-dom";

function Card11(props) {
    const { PendidikanHighlights } = props;
    return (
        <div>
            <div className="grid grid-cols-3 gap-8">
                {PendidikanHighlights.map((PendidikanItem, index) => (
                <div key={index}>
                    <div className="card card-compact h-full bg-neutral text-neutral-content shadow-xl">
                    <div className="card-body justify-between">
                        <h3>{PendidikanItem.pendidikan_kategori}</h3>
                        <h2 className="card-title">
                        <Link to={"/newsdetail/" + PendidikanItem.pendidikan_id}>
                            {PendidikanItem.pendidikan_program}
                        </Link>
                        </h2>
                        <div className="flex w-full">
                        <Link to={"/newsdetail/" + PendidikanItem.pendidikan_id}>
                            <button className="btn btn-primary">View</button>
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    );
}

export default Card11;
