import axios from "axios";
import { AppConfig } from "../../shared/utilities/AppConfig";
import { Article } from "../definitions/types";
import { PaginationInfo } from "../../shared/definitions/types";

export type GetDhiHomeResponse = {
  headline: Article[];
  berita: PaginationInfo<Article>;
  event: Article[];
  media: Article[];
};

export type GetDhiHomeParams = {
  page: number;
};

export default async function getDhiHome(params: GetDhiHomeParams) {
  const { data } = await axios.get<GetDhiHomeResponse>(
    `${AppConfig.API_URL}/dhi_home_v2`,
    { params },
  );

  return data;
}
