import NewsLatestSection from "../home/NewsLatestSection";

const ProfilNCBContent = () => {
  return (
    <div>
      <div className="mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl">
        <div className="flex flex-row my-12">
          <div className="p-4">
            <div className="card w-full bg-neutral text-neutral-content shadow-xl border-t-8 border-blue-600 ... rounded-none">
              <div className="card-body p-0">
                <div className="flex flex-row px-6 pt-6">
                  <div className="grow">
                    <h2 className="card-title text-2xl font-bold">
                      Profil NCB Interpol
                    </h2>
                  </div>
                  <div className="shrink">
                    <div className="text-sm breadcrumbs">
                      <ul>
                        <li>NCB-Interpol</li>
                        <li>
                          <a href="/visi-misi">Profil NCB Interpol</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="divider m-0"></div>

                <div className="mb-8">
                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      Sejarah NCB Interpol Indonesia
                    </p>
                    <p className="indent-8 pt-4">
                      Lahirnya organisasi ICPO-INTERPOL karena adanya persamaan
                      kepentingan dalam memberantas kejahatan transnasional dan
                      internasional diantara negara-negara di dunia, sebagai
                      berikut:
                    </p>
                    <ul className="list-outside list-decimal pl-8 ...">
                      <li>
                        perkembangan teknologi mengakibatkan dampak yang
                        signifikan dalam kehidupan internasional baik dampak
                        positif maupun negatif berupa meningkatnya kejahatan
                        internasional/transnasional. Teknologi yang secara
                        sosiologis mengakibatkan meningkatnya social mobility
                        dan social movement, juga perubahan yang mencolok pada
                        pola kejahatan di dunia dilihat dari segi organisasi,
                        peralatan, modus operandi dan daerah operasi,
                        kuantitas/kualitas hasil kejahatan, sehingga timbul
                        bentuk kejahatan yang disebut kejahatan internasional/
                        transnasional;
                      </li>

                      <li>
                        dalam menghadapi gejala-gejala tersebut setiap negara
                        mengalami kesulitan baik yang bersifat yuridis maupun
                        prosedur, karena masing-masing negara memiliki
                        kedaulatan dan sistem hukum yang berbeda;
                      </li>

                      <li>
                        untuk mengatasi permasalahan tersebut, beberapa negara
                        Eropa menyelenggarakan Kongres Polisi Reserse
                        Internasional ke-1 di Monaco tahun 1914 dan menghasilkan
                        kesepakatan untuk membentuk suatu Badan Kepolisian
                        Internasional. Pada Kongres ke-2 di Wina tahun 1923
                        telah disahkan suatu organisasi kepolisian internasional
                        yang disebut International Criminal Police Commission
                        (ICPC) dan Wina ditetapkan sebagai Markas Besar;
                      </li>

                      <li>
                        pada tahun 1956 nama ICPC berubah menjadi International
                        Criminal Police Organization (ICPO), dengan menggunakan
                        kata sandi INTERPOL dan juga sekaligus digunakan sebagai
                        alamat telegrafis dalam pertukaran informasi dan
                        komunikasi antar negara anggota. Pada tahun 1984 markas
                        besar ICPO-INTERPOL dipindahkan dari Wina ke Lyon,
                        Perancis;
                      </li>
                    </ul>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">1. 1914</p>
                    <p className="indent-8 pt-4">
                      Kongres Polisi Reserse Internasional ke-1 diadakan di
                      Monaco dari tanggal 14 s.d. 18 April 1914 dihadiri
                      beberapa ahli hukum dan perwira Polisi dari 14 negara
                      Eropa, yang membahas kemungkinan pengembangan
                      International Crime Records Office dan menyederhanakan
                      prosedur Ekstradisi. Pecahnya perang dunia pertama telah
                      menghambat perkembangan organisasi tersebut;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">2. 1923</p>
                    <p className="indent-8 pt-4">
                      Kongres Polisi Reserse Internasional ke-2 diadakan di
                      Wina, Austria, menyepakati pembentukan ICPC yang
                      berkedudukan di Wina, termasuk anggaran dasarnya. Presiden
                      ICPC adalah Kepala Kepolisian Wina, Austria, Dr Johannes
                      Schober, yang juga sebagai inisiator pembentukan
                      organisasi tersebut. ICPC masih beranggotakan
                      negara-negara Eropa;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">3. 1926</p>
                    <p className="indent-8 pt-4">
                      Sidang Umum ICPC di Berlin, menyarankan kepada setiap
                      negara anggota agar membentuk National Central Bureau
                      (NCB) sebagai pusat penghubung di dalam struktur
                      organisasi kepolisian;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">4. 1927</p>
                    <p className="indent-8 pt-4">
                      Sidang Umum ICPC telah menghasilkan resolusi untuk
                      membentuk NCB di setiap negara anggota;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">5. 1930</p>
                    <p className="indent-8 pt-4">
                      Pembentukan departemen khusus untuk mengelola data tentang
                      pemalsuan uang, catatan kejahatan dan pemalsuan paspor
                      dengan tujuan untuk pertukaran informasi antar negara
                      anggota;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">6. 1932</p>
                    <p className="indent-8 pt-4">
                      Setelah kematian Dr Schober, dibentuk suatu jabatan baru
                      yang diberi nama Sekretaris Jenderal ICPC. Sekjen yang
                      pertama adalah Kepala Kepolisian Austria, Mr. Oskar
                      Dressler;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">7. 1935</p>
                    <p className="indent-8 pt-4">
                      Jaringan radio komunikasi Internasional INTERPOL
                      diluncurkan;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">8. 1938</p>
                    <p className="indent-8 pt-4">
                      Nazi mengambil kendali setelah memecat Presiden Michael
                      Sjubl mengakibatkan banyak negara anggota tidak aktif
                      dalam kegiatan organisasi tersebut;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">9. 1942</p>
                    <p className="indent-8 pt-4">
                      ICPC berada dibawah kendali Jerman dan markas besarnya
                      dipindahkan ke Berlin;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">10. 1946</p>
                    <p className="indent-8 pt-4">
                      Setelah Perang Dunia ke II, diselenggarakan konferensi
                      ICPC di Brussels untuk menghidupkan kembali kegiatan
                      organisasi tersebut, dengan pengesahan Anggaran Dasar yang
                      baru. Markas Besar ICPC dipindahkan ke Paris dan kata
                      INTERPOL dipilih sebagai alamat telegrafis;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">11. 1949</p>
                    <p className="indent-8 pt-4">
                      PBB mengakui ICPC-INTERPOL sebagai organisasi
                      non-pemerintah;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">12. 1956</p>
                    <p className="indent-8 pt-4">
                      ICPC menjadi International Criminal Police Organization
                      INTERPOL disingkat ICPO-INTERPOL;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">13. 1958</p>
                    <p className="indent-8 pt-4">
                      ketentuan regulasi keuangan disahkan dan iuran kontribusi
                      negara anggota direvisi;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">14. 1963</p>
                    <p className="indent-8 pt-4">
                      Konferensi Regional pertama diadakan di Monrovia, Liberia;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">15. 1965</p>
                    <p className="indent-8 pt-4">
                      Sidang Umum menetapkan kebijakan tentang operasi dan
                      tanggung jawab NCB;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">16. 1966</p>
                    <p className="indent-8 pt-4">
                      Markas Besar Sekretariat Jenderal ICPO-INTERPOL
                      dipindahkan ke Saint Cloud di luar Paris;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">17. 1971</p>
                    <p className="indent-8 pt-4">
                      PBB mengakui ICPO-INTERPOL sebagai organisasi
                      inter-pemerintahan;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">18. 1972</p>
                    <p className="indent-8 pt-4">
                      Kesepakatan antara Markas Besar ICPO-INTERPOL dengan
                      Pemerintah Perancis tentang pengukuhan ICPO-INTERPOL
                      sebagai organisasi internasional;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">19. 1973</p>
                    <p className="indent-8 pt-4">
                      Organisasi merayakan hari ulang tahun ke-50 di Wina;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">20. 1982</p>
                    <p className="indent-8 pt-4">
                      Dibentuk badan independen untuk memantau pelaksanaan
                      ketentuan INTERPOL dalam memproteksi data yang selanjutnya
                      pada tahun 2003 berubah menjadi Komisi pengendalian file
                      INTERPOL;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">21. 1989</p>
                    <p className="indent-8 pt-4">
                      Pada tanggal 27 Nopember 1989 Markas Besar ICPO-INTERPOL
                      pindah; ke Lyon.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">22. 1990</p>
                    <p className="indent-8 pt-4">
                      Peluncuran sistem komunikasi INTERPOL X-400 digunakan oleh
                      seluruh NCB untuk mengirimkan pesan elektronik ke NCB
                      negara lain dan Sekretariat Jenderal;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">23. 1992</p>
                    <p className="indent-8 pt-4">
                      Anggota ICPO-INTERPOL berjumlah 169 negara. Penambahan
                      fasilitas automatic search (ASF) dalam database INTERPOL.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">24. 1994</p>
                    <p className="indent-8 pt-4">
                      Jumlah anggota ICPO-INTERPOL bertambah 176 negara;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">25. 1995</p>
                    <p className="indent-8 pt-4">
                      Sidang Umum mengesahkan pedoman pembentukan dan
                      pengoperasian beberapa Sub-Biro Regional;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">26. 1998</p>
                    <p className="indent-8 pt-4">
                      Pembangunan database INTERPOL Criminal Information System
                      (ICIS);
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">27. 2002</p>
                    <p className="indent-8 pt-4">
                      Peluncuran sistem komunikasi global kepolisian (IGCS)
                      I-24/7 menggantikan X-400. Kanada merupakan negara pertama
                      yang terkoneksi dengan sistem tersebut;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">28. 2003</p>
                    <p className="indent-8 pt-4">
                      Peresmian Pusat Komando dan Koordinasi (Command and
                      Coordination Centre) di Sekretariat Jenderal, yang
                      beroperasi 24 jam per hari, 7 hari dalam seminggu;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">29. 2004</p>
                    <p className="indent-8 pt-4">
                      Penempatan Perwakilan Khusus INTERPOL di Markas PBB New
                      York;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">30. 2005</p>
                    <p className="indent-8 pt-4">
                      Penerbitan Spesial Notice oleh ICPO-INTERPOL atas
                      permintaan Dewan Keamanan PBB terhadap individu dan
                      kelompok Al Qaeda dan Taliban dan afiliasinya;
                    </p>
                    <p className="indent-8 pt-4">
                      Peluncuran MIND/FIND (Mobile INTERPOL Network Database/Fix
                      INTERPOL Network Database) sebagai fasilitas bagi penegak
                      hukum di lapangan untuk pengecekan validitas paspor dan
                      kendaraan bermotor;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">31. 2008</p>
                    <p className="indent-8 pt-4">
                      Penempatan perwakilan khusus ICPO-INTERPOL di kantor pusat
                      Uni Eropa di Brussels;
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">32. 2011</p>
                    <p className="indent-8 pt-4">
                      Jumlah anggota ICPO-INTERPOL menjadi 190 negara dengan
                      masuknya Sudan Selatan, Curacao dan St Marteen.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">NCB-INTERPOL INDONESIA</p>
                    <p className="indent-8 pt-4">
                      Sekretariat NCB-INTERPOL Indonesia adalah salah satu Biro
                      yang berada dalam struktur organisasi Divisi Hubungan
                      Internasional Polri (Divhubinter Polri) yang bertugas
                      membina, mengawasi dan mengendalikan penyelengaraan tugas
                      NCB-INTERPOL dalam kerja sama internasional dalam lingkup
                      bilateral dan multilateral. Dalam melaksanakan tugasnya,
                      Set NCB-INTERPOL Indonesia menyelenggarakan fungsi:
                    </p>
                    <ul className="list-outside list-decimal pl-8 ...">
                      <li>
                        pelaksanaan kerja sama internasional dalam rangka
                        penanggulangan kejahatan internasional/transnasional;
                      </li>

                      <li>
                        penyusunan perjanjian internasional dan menyelenggarakan
                        forum pertemuan internasional, bilateral dan
                        multilateral;
                      </li>

                      <li>
                        pertukaran informasi intelijen kriminal melalui sistem
                        jaringan INTERPOL dan ASEANAPOL;
                      </li>

                      <li>pembinaan teknis Atase Polri, SLO, STP, dan LO.</li>
                    </ul>
                    <p className="indent-8 pt-4">
                      Sekretariat NCB-INTERPOL Indonesia dalam melaksanakan
                      tugasnya dibantu oleh Bagian Kejahatan Internasional
                      (Bagjatinter), Bagian Komunikasi Internasional
                      (Bagkominter), Bagian Konvensi Internasional
                      (Bagkonvinter), dan Bagian Liaison Officer dan Perbatasan
                      (Baglotas).
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      A. Bagian Kejahatan Internasional (BAGJATINTER)
                    </p>
                    <p className="pt-4 font-bold">
                      Tugas pokok, fungsi, dan peran
                    </p>
                    <p className="indent-8 pt-4">
                      Bagian Kejahatan Internasional (Bagjatinter) bertugas
                      melaksanakan kegiatan kerja sama antar anggota
                      NCB-INTERPOL dalam rangka pencegahan dan pemberantasan
                      kejahatan Internasional/transnasional serta pelayanan umum
                      internasional dalam kaitannya dengan kejahatan termasuk
                      pelaku, buronan dan bantuan hukum internasional.
                      Bagjatinter juga melaksanakan penyelidikan dan penyidikan
                      awal terhadap suatu pelanggaran/tindak pidana yang terjadi
                      di perwakilan RI, pesawat dan kapal berbendera RI guna
                      mewujudkan perlindungan, pelayanan terhadap WNI di luar
                      negeri.
                    </p>
                    <p className="pt-4">
                      Dalam melaksanakan tugas tersebut, Bagjatinter
                      menyelenggarakan fungsi:
                    </p>
                    <ul className="list-outside list-decimal pl-8 ...">
                      <li>penyiapan produk-produk internasional;</li>

                      <li>
                        penanggulangan kejahatan umum yang terkait dengan negara
                        lain;
                      </li>

                      <li>
                        penanggulangan kejahatan ekonomi khusus yang terkait
                        dengan negara lain;
                      </li>

                      <li>
                        pemberian bantuan hukum internasional yang terkait
                        Ekstradisi, MLA dan pencarian buronan/penerbitan
                        Notices.
                      </li>
                    </ul>

                    <p className="pt-4">
                      Dalam melaksanakan tugas tersebut, Bagjatinter dibantu
                      oleh:
                    </p>
                    <ul className="list-outside list-decimal pl-8 ...">
                      <li>Subbagjatum</li>

                      <li>Subbagjateksus</li>

                      <li>Subbagprodukinter</li>

                      <li>Subbagbankuminter</li>
                    </ul>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      B. Bagian Komunikasi Internasional (BAGKOMINTER)
                    </p>
                    <p className="pt-4 font-bold">
                      Tugas pokok, fungsi, dan peran
                    </p>
                    <p className="pt-4">
                      Sebagai salah satu bagian dari Sekretariat NCB-INTERPOL
                      Indonesia, Bagian Komunikasi Internasional atau disingkat
                      Bagkominter mempunyai tugas pokok sebagai berikut:
                    </p>
                    <ul className="list-outside list-decimal pl-8 ...">
                      <li>
                        melaksanakan kerja sama internasional dalam
                        penyelenggaraan dan pengembangan sistem pertukaran
                        informasi dalam upaya pencegahan dan pemberantasan
                        kejahatan internasional/ transnasional melalui sarana
                        sistem jaringan INTERPOL dan ASEANAPOL;
                      </li>

                      <li>
                        mengumpulkan informasi dan pengolahan data, publikasi
                        serta dokumentasi kerja sama internasional Polri.
                      </li>
                    </ul>

                    <p className="pt-4">
                      Dalam melaksanakan tugas pokok tersebut di atas,
                      Bagkominter menyelenggarakan fungsi:
                    </p>
                    <ul className="list-outside list-decimal pl-8 ...">
                      <li>
                        pertukaran informasi intelijen kriminal melalui sistem
                        jaringan INTERPOL, ASEANAPOL dan jaringan komunikasi
                        lainnya;
                      </li>

                      <li>
                        pembangunan, pemeliharaan dan pengembangan sistem
                        jaringan INTERPOL dan ASEANAPOL;
                      </li>

                      <li>
                        pengumpulan dan pengolahan data serta penyajian
                        informasi dan dokumentasi kegiatan Divhubinter serta
                        kegiatan internasional lainnya;
                      </li>

                      <li>
                        sebagai National Security Officer (NSO) jaringan I-24/7
                        di Indonesia;
                      </li>

                      <li>
                        sebagai fasilitator dalam sharing dan pertukaran
                        informasi internasional pada satuan kerja Polri,
                        seperti: CTINS, SDWAN, G8-24/7, TNCC (CMIS), NCIC
                        (Pusiknas).
                      </li>
                    </ul>

                    <p className="pt-4">
                      Dalam melaksanakan tugas dan fungsinya, Bagkominter
                      melakukan peran sebagai regulator, fasilitator dan
                      controller (quality insurance) dalam mendukung pelaksanaan
                      tugas Divhubinter Polri terutama dalam teknologi
                      informasi, data dan informasi serta publikasi dan
                      dokumentasi internasional. Pelaksanaan peran tersebut
                      dilaksanakan oleh:
                    </p>
                    <ul className="list-outside list-decimal pl-8 ...">
                      <li>Subbagtekkom</li>

                      <li>Subbaginfodata</li>

                      <li>Subbagpubdok</li>
                    </ul>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      C. Bagian Konvensi Internasional (BAGKONVINTER)
                    </p>
                    <p className="indent-8 pt-4">
                      Dalam konteks hubungan internasional istilah konvensi
                      kerap kali atau umum digunakan untuk perjanjian-perjanjian
                      multilateral yang beranggotakan banyak negara. Konvensi
                      umumnya memberikan kesempatan kepada masyarakat
                      internasional untuk berpartisipasi secara luas. Konvensi
                      biasanya bersifat law-making artinya merumuskan
                      kaidah-kaidah hukum bagi masyarakat internasional.
                      Perangkat-perangkat internasional yang dirundingkan atas
                      prakarsa/naungan organisasi internasional umumnya juga
                      menggunakan istilah konvensi.
                    </p>
                    <p className="indent-8 pt-4">
                      Terkait dengan pengertian tersebut, maka Bagian Konvensi
                      Internasional ( Bagkonvinter) memiliki tugas pokok,
                      fungsi, dan peran yang berkenaan dengan penyelenggaraan
                      konvensi-konvensi dan kerja sama Internasional yang
                      melibatkan Indonesia ( Polri ).
                    </p>
                    <p className="pt-4 font-bold">
                      Tugas pokok, fungsi, dan peran
                    </p>
                    <p className="indent-8 pt-4">
                      Bagkonvinter bertugas mempersiapkan pelaksanaan perjanjian
                      Internasional dan penyelenggaraan pertemuan internasional
                      baik dilaksanakan di dalam maupun di luar negeri dalam
                      rangka penanggulangan kejahatan
                      Internasional/transnasional dan pembangunan kapasitas baik
                      sumber daya manusia maupun sarana prasarana.
                    </p>
                    <p className="indent-8 pt-4">
                      Dalam pelaksanaan tugasnya, Bagkonvinter menyelenggarakan
                      fungsi pelaksanaan kegiatan penyiapan draft perjanjian
                      internasional; pelaksanaan pertemuan internasional,
                      bilateral, trilateral dan multilateral serta
                      penyelenggaraan Working Group Meeting dengan melibatkan
                      internal Polri dan instansi terkait yang yang memiliki
                      kompetensi dalam merumuskan perjanjian/kerja sama
                      internasional.
                    </p>
                    <p className="pt-4">
                      Dalam melaksanakan tugas, Bagkonvinter dibantu oleh:
                    </p>
                    <ul className="list-outside list-decimal pl-8 ...">
                      <li>Subbag Amerop</li>

                      <li>Subbag Aspasaf</li>

                      <li>Subbag Organisasi Internasional (OI)</li>

                      <li>Subbag Perjanjian Internasional (PI)</li>
                    </ul>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      D. BAGIAN LIAISON OFFICER DAN PERBATASAN (BAGLOTAS)
                    </p>
                    <p className="indent-8 pt-4">
                      Bagian Liaison Officer dan Perbatasan (baglotas) merupakan
                      salah satu bagian yang signifikan dalam menjalankan roda
                      dan sistem organisasi pada Divisi Hubungan Internasional
                      Polri. Hal ini sejalan dengan dinamika dunia atau global
                      trend yang perlu dicermati secara cerdas mengingat
                      perkembangan peradaban manusia (human civilization) yang
                      semakin maju dan kompleks terutama ditandai dengan
                      perkembangan ilmu pengetahuan dan teknologi demikian juga
                      kejahatan transnasional terorganisasi (transnational
                      organized crime) yang tidak mengenal batas (borderless).
                      Posisi negara Indonesia yang strategis bahkan sebagai
                      negara kepulauan (archipelagic country) yang memiliki
                      puluhan ribu pulau dan garis pantai serta perairan yang
                      begitu luas menghadapi berbagai tantangan (challenges)
                      bagi para key actors (khususnya Atase Polri, SLO, Staf
                      Teknis Polri dan LO) dalam memainkan peran/interaksi dalam
                      lingkungan strategis demi kepentingan negara (national
                      interest). Dengan kata lain, baglotas sebagai key element
                      dalam mengantisipasi tantangan tersebut menjadi peluang
                      (opportunities) yakni salah satunya adalah profesionalisme
                      tugas dan optimalisasi fungsi yang mengarah pada outcome
                      pelayanan prima masyarakat.
                    </p>
                    <p className="pt-4 font-bold">
                      Tugas pokok, fungsi, dan peran
                    </p>
                    <p className="indent-8 pt-4">
                      Baglotas bertugas melaksanakan pembinaan teknis Atase
                      Polri, SLO, Staf Teknis Polri dan LO di luar negeri
                      termasuk sumber daya manusia Polri dan sarana prasarana
                      tugas Polri di perbatasan.
                    </p>
                    <p className="pt-4">
                      Dalam melaksanakan tugas, Baglotas menyelenggarakan fungsi
                      :
                    </p>
                    <ul className="list-outside list-decimal pl-8 ...">
                      <li>
                        pembinaan Atase Polri, SLO, Staf Teknis Polri dan LO.
                      </li>

                      <li>
                        pembinaan teknis Polri termasuk sumber daya manusia dan
                        sarana prasarana tugas di wilayah perbatasan.
                      </li>

                      <li>
                        pelaksanaan koordinasi dengan penegak hukum atau LO
                        negara lain di Indonesia.
                      </li>
                    </ul>

                    <p className="indent-8 pt-4">
                      Dalam melaksanakan tugas, Baglotas dibantu oleh:
                    </p>
                    <ul className="list-outside list-decimal pl-8 ...">
                      <li>Subbag LO</li>

                      <li>Subbagbatas</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilNCBContent;
