import axios from "axios";
import React, { useState, useEffect } from "react";

const LOAsing = () => {

const [atase, setAtase] = useState([]);
useEffect(() => {
    const getAtase = async () => {
    const response = await axios.get(
        "https://divhubinter.polri.go.id/api/view_atase"
    );
    setAtase(response.data.atase.data);
    };

    getAtase();
}, []);

const [staftek, setStaftek] = useState([]);
useEffect(() => {
    const getStaftek = async () => {
    const response = await axios.get(
        "https://divhubinter.polri.go.id/api/view_staftek"
    );
    setStaftek(response.data.staf_teknis.data);
    };

    getStaftek();
}, []);

return (
    <div>
    <div className="container mx-auto px-12 py-8">
        <h2 className="text-4xl font-bold pb-4">ATPOL & STP</h2>
        {/* ATPOL */}
        <div className="flex flex-col px-6 gap-6">
            <div className="flex flex-col gap-4">
                <div className="font-bold">
                    ATASE POLRI
                </div>
                <div className="laptop:grid laptop:grid-cols-2 gap-6 laptop:px-4">
                {atase.map((atase)=>(
                    <div key={atase.atpolstp_id}>
                        <div className="flex flex-flex-row gap-6">
                            <div className="relative overflow-hidden w-1/2 rounded-lg">
                                <div className="mx-auto max-w-screen">
                                    <img
                                        src={`https://divhubinter.polri.go.id` + atase.atpolstp_foto}
                                        alt=""
                                        width={0}
                                        height={0}
                                        sizes="100vw"
                                        className="w-full h-full"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-4 w-3/4">
                                <div className="font-sf-bold text-lg">{atase.atpolstp_title}</div>
                                <div className="text-base">{atase.atpolstp_nama}</div>
                                <div className="flex flex-col gap-3">
                                    <div className="">
                                        <div dangerouslySetInnerHTML={{ __html: atase.atpolstp_konten }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                    
                </div>
            </div>
            <div className="flex flex-col gap-4 mt-6">
                <div className="font-bold">
                    STAF TEKNIS POLRI
                </div>
                <div className="laptop:grid laptop:grid-cols-2 gap-6 laptop:px-4">
                    {staftek.map((staf)=>(
                        <div key={staf.atpolstp_id}>
                            <div className="flex flex-flex-row gap-6">
                                <div className="relative overflow-hidden w-1/2 rounded-lg">
                                    <div className="mx-auto max-w-screen">
                                        <img
                                            src={`https://divhubinter.polri.go.id` + staf.atpolstp_foto}
                                            alt=""
                                            width={0}
                                            height={0}
                                            sizes="100vw"
                                            className="w-full h-full"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 w-3/4">
                                    <div className="font-sf-bold text-lg">{staf.atpolstp_title}</div>
                                    <div className="text-base">{staf.atpolstp_nama}</div>
                                    <div className="flex flex-col gap-3">
                                        <div className="">
                                            <div dangerouslySetInnerHTML={{ __html: staf.atpolstp_konten }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
    </div>
);
};

export default LOAsing;
