import { Link } from "react-router-dom";

function Card07(props) {
    const { KejahatanTransnasionalHighlights } = props;
    return (
        <div>
            <div className="laptop:grid laptop:grid-cols-3 gap-8">
                {KejahatanTransnasionalHighlights.map((KejahatanTransnasionalItem, index) => (
                <div key={index}>
                    <div className="card card-compact h-full bg-neutral text-neutral-content shadow-xl">
                    <figure className="aspect-[4/3] bg-cover">
                        <img 
                        src={KejahatanTransnasionalItem.artikel_thumbnail ? `https://divhubinter.polri.go.id/` + KejahatanTransnasionalItem.artikel_thumbnail : "/dhi.png"}
                        alt="Null" 
                        className="w-full h-full"
                        />
                    </figure>
                    <div className="card-body justify-between">
                        <h3>{KejahatanTransnasionalItem.artikel_jenis}</h3>
                        <h2 className="card-title">
                        <Link to={"/newsdetail/" + KejahatanTransnasionalItem.artikel_id}>
                            {KejahatanTransnasionalItem.artikel_judul}
                        </Link>
                        </h2>
                        <div className="flex w-full">
                        <Link to={"/newsdetail/" + KejahatanTransnasionalItem.artikel_id}>
                            <button className="btn btn-primary">View</button>
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    );
}

export default Card07;
