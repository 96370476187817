import BeritaContent from "../components/layouts/berita/BeritaContent";

const Berita = () => {
  return (
    <div>
      <BeritaContent />
    </div>
  );
};

export default Berita;
