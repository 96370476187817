import { Link } from "react-router-dom";
function Card03(props) {
  const { newsHighlights } = props;

  const BrokenImage = "/dhi.png";

  const imageOnError = (event) => {
    event.currentTarget.src = BrokenImage;
    event.currentTarget.className = "flex items-center object-contain h-32 w-32";
  };

  return (
    <div>
      <div className="laptop:grid laptop:grid-cols-3 gap-8">
        {newsHighlights.map((newsItem, index) => (
          <div key={index}>
            <div className="card card-compact h-full bg-neutral text-neutral-content shadow-xl">
              <figure className="flex items-center bg-cover aspect-[16/9]">
                <img src={newsItem.artikel_thumbnail ? `https://divhubinter.polri.go.id/` + newsItem.artikel_thumbnail : "/dhi.png"} 
                  onError={imageOnError}
                  alt="Null" 
                  className="object-contain"
                />
              </figure>
              <div className="card-body justify-between">
                <h3>{newsItem.artikel_jenis}</h3>
                <h2 className="card-title">
                  <Link to={"/newsdetail/" + newsItem.artikel_id}>
                    {newsItem.artikel_judul}
                  </Link>
                </h2>
                <div className="flex w-full">
                  <Link to={"/newsdetail/" + newsItem.artikel_id}>
                    <button className="btn btn-primary">View</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Card03;
