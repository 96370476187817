import NewsLatestSection from "../home/NewsLatestSection";

const VisiMisiContent = () => {
  return (
    <div>
      <div className="mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl">
        <div className="flex flex-row my-12">
          <div className="p-4">
            <div className="card w-full bg-neutral text-neutral-content shadow-xl border-t-8 border-blue-600 ... rounded-none">
              <div className="card-body p-0">
                <div className="flex flex-row px-6 pt-6">
                  <div className="grow">
                    <h2 className="card-title text-2xl font-bold">
                      Tim Koordinasi
                    </h2>
                  </div>
                  <div className="shrink">
                    <div className="text-sm breadcrumbs">
                      <ul>
                        <li>Tentang</li>
                        <li>
                          <a href="/visi-misi">Tim Koordinasi</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="divider m-0"></div>

                <div className="mb-8">
                  <div className="mx-12">
                    <p className="indent-8">
                      NCB-Interpol, sebagaimana ditetapkan dalam konstitusi
                      organisasi Interpol mengemban fungsi sebagai koordinator
                      terkait tingkat nasional dalam rangka penanggulangan
                      kejahatan internasional baik di dalam maupun di luar
                      negeri. Dengan demikian NCB-Interpol mempunyai kaitan yang
                      erat dengan semua instansi terkait di dalam negeri, karena
                      dalam prosedur pelaksanaannya tugas NCB-Interpol
                      menyangkut kewenangan berbagai instansi. Berkaitan dengan
                      hal tersebut, maka berdasarkan Surat Keputusan Kapolri No.
                      Pol.: Skep/203/V/1992 tanggal 9 Mei 1992 dibentuklah Tim
                      Koordinasi Interpol.
                    </p>
                    <p className="indent-8 pt-4">
                      Tim Koordinasi Interpol merupakan wadah koordinasi dan
                      kerjasama yang bersifat non struktural yang berada dibawah
                      dan bertanggungjawab kepada Kapolri selaku Kepala
                      NCB-Interpol Indonesia yang dalam kegiatan sehari-hari
                      dikoordinasikan oleh Sekretaris NCB-Interpol Indonesia.
                    </p>
                  </div>
                </div>

                <div className="mb-8">
                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      Keanggotaan Tim Koordinasi Interpol
                    </p>
                    <p className="indent-8 pt-4">
                      Tim Koordinasi Interpol terdiri dari pejabat Polri dan
                      pejabat Instansi terkait, dengan jabatan dibedakan sebagai
                      "anggota" dan "Liaison Officer" (LO). "Anggota" Tim
                      Koordinasi adalah pejabat setingkat Dirjen atau eselon II,
                      sedangkan "LO" adalah pejabat setingkat eselon III.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      Misi Divisi Hubungan Internasional Polri:
                    </p>
                    <p className="">
                      Instansi yang menjadi Tim Koordinasi Interpol adalah
                      sebagai berikut:
                    </p>
                    <ul className="list-outside list-disc pl-8 ...">
                      <li>POLRI</li>
                      <li>Bank Indonesia</li>
                      <li>Departemen Hukum dan HAM</li>
                      <li>Kejaksaan Agung</li>
                      <li>Departemen Luar Negeri</li>
                      <li>Departemen Industri</li>
                      <li>Departemen Perdagangan</li>
                      <li>Departemen Perhubungan</li>
                      <li>Departemen Pendidikan Nasional</li>
                      <li>Departemen Tenaga Kerja dan Transmigrasi</li>
                      <li>Departemen Keuangan</li>
                      <li>Departemen Komunikasi dan Informasi</li>
                      <li>Departemen Kelautan dan Perikanan</li>
                      <li>Kantor Meneg Pemberdayaan Perempuan</li>
                      <li>Kantor Meneg Kebudayaan dan Pariwisata</li>
                      <li>Badan Intelijen Nasional</li>
                      <li>Badan POM</li>
                      <li>Peruri</li>
                      <li>Botasupal</li>
                      <li>PPATK</li>
                      <li>BNN</li>
                    </ul>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">Maksud dan Tujuan</p>
                    <ul className="list-outside list-disc pl-8 ...">
                      <li>
                        Memperlancar dan mempercepat serta meningkatkan
                        kerjasama dan koordinasi antar komponen-komponen Polri
                        dan Instansi lain yang terkait dengan bidang tugas
                        NCB-Interpol Indonesia.
                      </li>
                      <li>
                        Agar permintaan bantuan dari NCB negara lain dan Sekjen
                        ICPO-Interpol dapat dipenuhi dengan cepat, tepat dan
                        lengkap.
                      </li>
                      <li>
                        Agar permintaan bantuan dari komponen-komponen Polri dan
                        Instansi lain kepada NCB negara lain dan Sekjen
                        ICPO-Interpol dapat dilaksanakan dengan cepat dan tepat.
                      </li>
                    </ul>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">Tugas</p>
                    <ul className="list-outside list-disc pl-8 ...">
                      <li>
                        Membahas dan melaksanakan setiap permintaan bantuan baik
                        dari NCB negara lain maupun dari dalam negeri khususnya
                        terhadap hal-hal yang menonjol yang memerlukan kerjasama
                        dan koordinasi lintas sektoral.
                      </li>
                      <li>
                        Tukar menukar informasi mengenai hal-hal yang berkaitan
                        dengan masalah-masalah yang beraspek internasional.
                      </li>
                    </ul>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">Tata Cara Kerja</p>
                    <ul className="list-outside list-disc pl-8 ...">
                      <li>
                        Mengadakan pertemuan secara berkala dan atau setiap
                        waktu diperlukan.
                      </li>
                      <li>
                        Berdasarkan hasil pertemuan, Ketua Tim Penyusun akan
                        menyampaikan usulan dan saran tindak bagi penyelesaian
                        masalah yang sangat khusus kepada Kapolri selaku Kepala
                        NCB-Interpol Indonesia.
                      </li>
                      <li>
                        Pelaksanaan tugas dilakukan secara fungsional sesuai
                        dengan kewenangan masing-masing berdasarkan peraturan
                        dan perundang-undangan yang berlaku.
                      </li>
                      <li>
                        Tata cara kerja lebih lanjut, termasuk prosedur
                        pelaksanaan (jika diperlukan), ditetapkan berdasarkan
                        kesepakatan Tim Koordinasi Interpol.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisiMisiContent;
