// import NewsHighlightSection from "./NewsHighlightSection";
// import NewsLatestSection from "./NewsLatestSection";
// import NewsHighlightSection from "@/src/modules/homepage/component/NewsHighlightSection";

import ArticleHighlightSection from "../../../modules/homepage/component/ArticleHighlightSection";
import NewsEventSection from "./NewsEventSection";
import ArticleLatestSection from "../../../modules/homepage/component/ArticleLatestSection";

// import NewsHighlightSectionTerorisme from "./NewsHighlightSectionTerorisme";
// import NewsHighlightSectionNarkoba from "./NewsHighlightSectionNarkoba";
// import NewsHighlightSectionPerdaganganOrang from "./NewsHighlightSectionPerdaganganOrang";
// import NewsHighlightSectionKejahatanLaut from "./NewsHighlightSectionKejahatanLaut";
// import NewsHighlightSectionPencucianUang from "./NewsHighlightSectionPencucianUang";
// import NewsHighlightSectionSenjata from "./NewsHighlightSectionSenjata";
// import NewsHighlightSectionDuniaMaya from "./NewsHighlightSectionDuniaMaya";
// import NewsHighlightSectionEkonomi from "./NewsHighlightSectionEkonomi";

const Dashboard = () => {
  return (
    <div>
      <div className="mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl ">
        <div className="laptop:grid laptop:grid-cols-3 my-12">
          <div className="flex flex-col col-span-2 p-4">
            <ArticleLatestSection />
            <br />
            {/* <NewsHighlightSectionTerorisme />
            <br />
            <NewsHighlightSectionNarkoba />
            <br />
            <NewsHighlightSectionPerdaganganOrang />
            <br />
            <NewsHighlightSectionKejahatanLaut />
            <br />
            <NewsHighlightSectionPencucianUang />
            <br />
            <NewsHighlightSectionSenjata />
            <br />
            <NewsHighlightSectionDuniaMaya />
            <br />
            <NewsHighlightSectionEkonomi /> */}
          </div>

          <div className="flex flex-col gap-8 p-4">
            <div className="basis-1/1">
              <ArticleHighlightSection />
            </div>

            <div className="basis-1/1">
              <NewsEventSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
