import Card01 from "../../../components/layouts/home/Card/Card01";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import useQueryDhiHome from "../hooks/useQueryDhiHome";
import { classNames } from "../../shared/utilities/common";

const ArticleLatestSection = () => {
  const {
    data: dhiHomeData,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useQueryDhiHome();
  const beritaArticlesWithPagination = dhiHomeData?.pages.flatMap(
    (page) => page.berita,
  );
  if (!beritaArticlesWithPagination) return null;
  const beritaArticles = beritaArticlesWithPagination.flatMap(
    (page) => page.data,
  );
  return (
    <div className="card w-full bg-neutral text-neutral-content shadow-xl p-6">
      <div className="flex justify-left items-center">
        <FontAwesomeIcon
          icon={["fas", "newspaper"]}
          className="text-blue-400 w-5 h-5"
        />
        <h2 className="text-2xl font-bold pl-2">Latest News</h2>
      </div>

      <hr className="divider"></hr>
      <Card01 articles={beritaArticles} />
      {hasNextPage ? (
        <button
          className={classNames("btn flex", isFetching ? "!cursor-wait" : "")}
          disabled={isFetching}
          onClick={() => fetchNextPage()}
        >
          Lihat lebih banyak
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ArticleLatestSection;
