import VisiMisiContent from "../components/layouts/tentang/VisiMisiContent";

const VisiMisi = () => {
  return (
    <div>
      <VisiMisiContent />
    </div>
  );
};

export default VisiMisi;
