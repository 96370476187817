import React, { Fragment } from "react";
import { motion } from "framer-motion";

export type Slide = {
  number: string | React.ReactNode;
};

const _slides: Slide[] = [
  {
    number: (
      <div className="flex-shrink-0 bg-[#204d84] w-full flex-nowrap">
        <div className="flex w-full h-full flex-col items-center justify-center text-base text-white">
          Divhubinter Polri
        </div>
      </div>
    ),
  },
];

const InfiniteSlider: React.FC<{
  slides?: Slide[];
}> = ({ slides = _slides }) => {
  // Duplicate the slides array to ensure seamless looping
  const duplicatedSlides = [...slides, ...slides];

  return (
    <div className="relative w-full overflow-hidden">
      {/* Wrapping div for seamless looping */}
      <motion.div
        className="flex bg-[#204d84]"
        animate={{
          x: ["0%", "-100%"],
          transition: {
            ease: "linear",
            duration: 12,
            repeat: Infinity,
          },
        }}
      >
        {/* Render duplicated slides */}
        {duplicatedSlides.map((slide, index) => (
          <Fragment key={index}>{slide.number}</Fragment>
        ))}
      </motion.div>
    </div>
  );
};

export default InfiniteSlider;
