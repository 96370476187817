import UuDetailContent from "../components/layouts/berita_detail/UuDetailContent";

const UuDetail = () => {
  return (
    <div>
      <UuDetailContent />
    </div>
  );
};

export default UuDetail;
