import { useEffect, useState } from "react";

export const useWindowWidth = (): {
  windowWidth: number | undefined;
  isMdScreen: boolean;
} => {
  const [windowWidth, setWindowSize] = useState<number | undefined>(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width to state
      setWindowSize(window.innerWidth);
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const isMdScreen = (windowWidth && windowWidth >= 768) || false;

  return { windowWidth, isMdScreen };
};
