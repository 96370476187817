import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../pages/Home";
import VisiMisi from "../pages/VisiMisi";
import Profil from "../pages/Profil";
import StrukturOrganisasi from "../pages/Struktur Organisasi";
import TugasFungsi from "../pages/TugasFungsi";
import AtpolSTP from "../pages/AtpolSTP";
import LOAsing from "../pages/LOAsing";
import TimKoordinasi from "../pages/TimKoordinasi";
import ProfilNCB from "../pages/ProfilNCB";
import KejahatanTransnasional from "../pages/KejahatanTransnasional";
import UUHukum from "../pages/UUHukum";
import MediaRelease from "../pages/MediaRelease";
import ProfilMisiInt from "../pages/ProfilMisiInt";
import MisiPBB from "../pages/MisiPBB";
import Pendidikan from "../pages/Pendidikan";
import PelatihanPersonil from "../pages/PelatihanPersonil";
import Berita from "../pages/Berita";
import Event from "../pages/Event";
import Kontak from "../pages/Kontak";
import NewsDetail from "../pages/NewsDetail";
import UuDetail from "../pages/UuDetail";
import AppLayout from "../modules/shared/component/AppLayout";

export type MainAppWrapperProps = {
  theme: string;
  toggleTheme: () => void;
};

const MainAppWrapper: React.FC<MainAppWrapperProps> = ({
  theme,
  toggleTheme,
}) => {
  return (
    <Routes>
      <Route element={<AppLayout theme={theme} toggleTheme={toggleTheme} />}>
        <Route path="/" element={<Home />}></Route>
        {/* Menu Tentang */}
        <Route path="/visi-misi" element={<VisiMisi />} />
        <Route path="/profil" element={<Profil />} />
        <Route path="/struktur-organisasi" element={<StrukturOrganisasi />} />
        <Route path="/tugas-fungsi" element={<TugasFungsi />} />
        <Route path="/atpol-stp" element={<AtpolSTP />} />
        <Route path="/lo-asing" element={<LOAsing />} />
        <Route path="/tim-koordinasi" element={<TimKoordinasi />} />

        {/* NCB-Interpol */}
        <Route path="/profil-ncb" element={<ProfilNCB />} />
        <Route
          path="/kejahatan-transnasional"
          element={<KejahatanTransnasional />}
        />
        <Route path="/uu-hukum" element={<UUHukum />} />
        <Route path="/uudetail/:id" element={<UuDetail />} />
        <Route path="/media-release" element={<MediaRelease />} />

        {/* Misi Internasional */}
        <Route path="/profil-misinter" element={<ProfilMisiInt />} />
        <Route path="/misi-pbb" element={<MisiPBB />} />
        <Route path="/pendidikan" element={<Pendidikan />} />
        <Route path="/pelatihan-personil" element={<PelatihanPersonil />} />

        <Route path="/berita" element={<Berita />} />
        <Route path="/newsdetail/:id" element={<NewsDetail />} />
        <Route path="/berita/:search" element={<Berita />} />
        <Route path="/event" element={<Event />} />
        <Route path="/kontak" element={<Kontak />} />
      </Route>
    </Routes>
  );
};

export default MainAppWrapper;
