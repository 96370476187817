import { Link } from "react-router-dom";
import { Article } from "../../../../modules/homepage/definitions/types";
import React from "react";

export type Card01Props = {
  articles: Article[];
};
function Card01(props: Card01Props) {
  const { articles } = props;

  const BrokenImage = "/dhi.png";
  const imageOnError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = BrokenImage;
    event.currentTarget.className =
      "flex items-center object-contain h-64 w-64";
  };

  return (
    <div>
      {articles.map((article, index) => (
        <div
          key={index}
          className="cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
        >
          <div className="card flex rounded-none">
            <div className="card-body gap-5">
              <figure className="flex items-center p-6 aspect-[16/9]">
                <img
                  src={
                    article?.artikel_thumbnail
                      ? `https://divhubinter.polri.go.id/` +
                        article.artikel_thumbnail
                      : "/dhi.png"
                  }
                  onError={imageOnError}
                  alt=""
                  className="object-contain"
                />
              </figure>
              <div className="flex flex-row justify-between w-full">
                <div className="flex">{article?.artikel_jenis}</div>
              </div>
              <h2 className="card-title">
                <Link to={"newsdetail/" + article?.artikel_id}>
                  {article?.artikel_judul}
                </Link>
              </h2>
            </div>
          </div>

          <hr className="divider"></hr>
        </div>
      ))}
    </div>
  );
}

export default Card01;
