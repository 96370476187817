import axios from "axios";
import React, { useState, useEffect } from "react";

const LOAsingContent = () => {
  const [liason, setLiason] = useState([]);
  useEffect(() => {
    const getLiason = async () => {
      const response = await axios.get(
        "https://divhubinter.polri.go.id/api/view_lo"
      );
      setLiason(response.data.liason_officer);
    };

    getLiason();
  }, []);

  return (
    <div>
      <div className="mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl px-12 py-8">
        <h2 className="text-4xl font-bold pb-4">Liason Officer Asing</h2>
        <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-dark">
          <thead className="bg-neutral">
            <tr>
              <th className="px-5 py-2 uppercase tracking-wider text-left">NO</th>
              <th className="px-5 py-2 uppercase tracking-wider text-left">NAMA</th>
              <th className="px-5 py-2 uppercase tracking-wider text-left">NEGARA</th>
              <th className="px-5 py-2 uppercase tracking-wider text-left">INSTITUSI</th>
              <th className="px-5 py-2 uppercase tracking-wider text-left">JABATAN</th>
              <th className="px-5 py-2 uppercase tracking-wider text-left">KONTAK</th>
              <th className="px-5 py-2 uppercase tracking-wider text-left">EMAIL</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-dark">
            {liason.map((item) => (
              <tr key={item.lo_id}>
                <td className="px-5 py-2 whitespace-nowrap">{item.lo_id}</td>
                <td className="px-5 py-2 whitespace-nowrap">{item.lo_nama}</td>
                <td className="px-5 py-2 whitespace-nowrap">{item.lo_negara}</td>
                <td className="px-5 py-2 whitespace-nowrap">{item.lo_institusi}</td>
                <td className="px-5 py-2 whitespace-nowrap">{item.lo_jabatan}</td>
                <td className="px-5 py-2 whitespace-nowrap">{item.lo_kontak}</td>
                <td className="px-5 py-2 whitespace-nowrap">{item.lo_email}</td>
              </tr>
            ))}
          </tbody>
        </table>

        </div>
      </div>
    </div>
  );
};

export default LOAsingContent;
