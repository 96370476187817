import { Link } from "react-router-dom";
import React from "react";
import { Article } from "@/src/modules/homepage/definitions/types";

interface Card02Props {
  newsLatest: Article[];
}

const Card02: React.FC<Card02Props> = ({ newsLatest }) => {
  const BrokenImage = "/dhi.png";

  const imageOnError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = BrokenImage;
    event.currentTarget.className =
      "flex items-center object-contain h-32 w-32";
  };

  return (
    <div>
      {newsLatest.map((newsItem, index) => (
        <div
          key={index}
          className="cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
        >
          <div className="card card-compact rounded-none">
            <figure className="flex items-center p-6 aspect-[16/9]">
              <img
                src={
                  newsItem.artikel_thumbnail
                    ? `https://divhubinter.polri.go.id/${newsItem.artikel_thumbnail}`
                    : "/dhi.png"
                }
                onError={imageOnError}
                alt=""
                className="object-contain"
              />
            </figure>
            <div className="card-body">
              <p className="text-sm text-left">{newsItem.artikel_jenis}</p>
              <h3 className="card-title">
                <Link to={`newsdetail/${newsItem.artikel_id}`}>
                  {newsItem.artikel_judul}
                </Link>
              </h3>

              {/* <p>{newsItem.description}</p> */}
            </div>
          </div>

          <hr className="divider"></hr>
        </div>
      ))}
    </div>
  );
};

export default Card02;
