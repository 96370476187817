import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../utilities/common";

/**
 * @params sourcePath - The path of the source path where the component is used, used for determining the active menu item.
 */
export type MenuItemProps = {
  link?: string;
  icon?: React.JSX.Element | React.ReactNode;
  label: string | React.JSX.Element;
  description?: string;
  onClick?: () => void;
  className?: string;
  sourcePath?: string;
};

const MenuItem: React.FC<MenuItemProps> = ({
  link,
  icon,
  label,
  description,
  onClick,
  className,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <Link
      to={link || "#"}
      data-testid="MenuItem"
      className={classNames(
        "flex flex-row items-center py-[18px]",
        className ?? "",
      )}
      onClick={handleClick}
    >
      {icon}
      <div className={classNames(icon ? "ml-4" : "", "flex-1")}>
        <div className="text-white">{label}</div>
        {description && (
          <div className="mt-0.5 text-sm text-neutral-80">{description}</div>
        )}
      </div>
    </Link>
  );
};

export default MenuItem;
