import { Link } from "react-router-dom";

function Card12(props) {
    const { PelatihanPersonilHighlights } = props;
    return (
        <div>
            <div className="grid grid-cols-3 gap-8">
                {PelatihanPersonilHighlights.map((PelatihanPersonilItem, index) => (
                <div key={index}>
                    <div className="card card-compact h-full bg-neutral text-neutral-content shadow-xl">
                    <figure className="aspect-[4/3] bg-cover">
                        <img src={`https://divhubinter.polri.go.id/` + PelatihanPersonilItem.pelatihan_image} 
                        alt="Null" 
                        className="w-full h-full"
                        />
                    </figure>
                    <div className="card-body justify-between">
                        <h3>{PelatihanPersonilItem.pelatihan_kategori}</h3>
                        <h2 className="card-title">
                        <Link to={"/newsdetail/" + PelatihanPersonilItem.pelatihan_id}>
                            {PelatihanPersonilItem.pelatihan_title}
                        </Link>
                        </h2>
                        <div className="flex w-full">
                        <Link to={"/newsdetail/" + PelatihanPersonilItem.pelatihan_id}>
                            <button className="btn btn-primary">View</button>
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    );
}

export default Card12;
