import PendidikanContent from "../components/layouts/misi_internasional/PendidikanContent";

const Pendidikan = () => {
  return (
    <div>
      <PendidikanContent />
    </div>
  );
};

export default Pendidikan;
