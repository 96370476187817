const LoadingSpinner = () => {
  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <div className="loading">
            <div className="btn btn-circle btn-outline w-28 h-28">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animate-spin bg-blue-600 w-24 h-24 rounded-full ..."
              >
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12H19C19 15.866 15.866 19 12 19V22Z"
                  fill="white"
                ></path>
                <path
                  d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
                  fill="white"
                ></path>
              </svg>
            </div>

            <p className="text-3xl font-bold tracking-widest py-6">
              DIVHUBINTER POLRI
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
