import { Link } from "react-router-dom";

function Card10(props) {
    const { MisiPBBHighlights } = props;
    return (
        <div>
            <div className="laptop:grid laptop:grid-cols-3 gap-8">
                {MisiPBBHighlights.map((MisiPBBItem, index) => (
                <div key={index}>
                    <div className="card card-compact h-full bg-neutral text-neutral-content shadow-xl">
                    <figure className="bg-cover">
                        <img src={`https://divhubinter.polri.go.id/` + MisiPBBItem.misinter_image} 
                        alt="Null" 
                        className="object-cover h-64"
                        />
                    </figure>
                    <div className="card-body justify-between">
                        <h3>{MisiPBBItem.misinter_kategori}</h3>
                        <h2 className="card-title">
                        <Link to={"/newsdetail/" + MisiPBBItem.misinter_id}>
                            {MisiPBBItem.misinter_kategori} {MisiPBBItem.misinter_title} {MisiPBBItem.misinter_tahun}
                        </Link>
                        </h2>
                        <div className="flex w-full">
                        <Link to={"/newsdetail/" + MisiPBBItem.misinter_id}>
                            <button className="btn btn-primary">View</button>
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    );
}

export default Card10;
