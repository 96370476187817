import NewsLatestSection from "../home/NewsLatestSection";


const ProfilContent = () => {
  return (
    <div>
      <div className="mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl">
        <div className="flex flex-row my-12">
          <div className="p-4">
            <div className="card w-full bg-neutral text-neutral-content shadow-xl border-t-8 border-blue-600 ... rounded-none">
              
              <div className="card-body p-0">
                <div className="flex flex-row px-6 pt-6">
                  <div className="grow">
                    <h2 className="card-title text-2xl font-bold">Profil</h2>
                  </div>                
                  <div className="shrink">
                    <div className="text-sm breadcrumbs">
                      <ul>
                        <li>Tentang</li> 
                        <li><a href="/visi-misi">Profil</a></li> 
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="divider m-0"></div> 

                <div className="mb-8">
                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      Sejarah Terbentuknya Divhubinter Polri
                    </p>
                    <p className="indent-8 pt-4">
                      Sejalan dengan tujuan nasional yang diamanatkan dalam Pembukaan Undang-undang Dasar Negara Republik Indonesia Tahun 1945 alenia ke-4 dan RPJPN tahun 2005 – 2025, maka Polri sebagai bagian dari aparatur pemerintahan Indonesia telah berperan aktif di dalam mewujudkan kerja sama Internasional berupa penanganan kejahatan transnasional dan kejahatan internasional, penggelaran personel Polri dalam misi pemeliharaan perdamaian Perserikatan Bangsa-Bangsa (PBB) dan misi kemanusiaan dalam konteks perbantuan tenaga ahli pada daerah atau negara yang mengalami bencana alam. Disamping itu Polri secara aktif dan berkelanjutan juga melaksanakan misi pengembangan kapasitas serta memberikan perlindungandan pelayanan bagi warga negara Indonesia (WNI) di luar negeri.
                    </p>
                    <p className="indent-8 pt-4">
                      Apa yang dilakukan oleh Polri tersebut di atas merupakan penjabaran misi Polri sesuai dengan Grand Strategy Polri tahun 2005 – 2025 yang didalamnya terdapat wujud nyata dari Perpolisian Internasional yang menjadi tugas pokok dan tanggung jawab dari Divisi Hubungan Internasional Polri ( Divhubinter Polri ).
                    </p>
                    <p className="indent-8 pt-4">
                      Divhubinter Polri sebagai salah satu unsur pembantu pimpinan Polri terbentuk sejak tahun 2010. Namun keberadaan dan pelaksanaan tugas pokoknya dalam bidang hubungan dan kerjasama Internasional sudah ada sejak tahun 1952 yang pada saat itu dilaksanakan oleh NCB INTERPOL Indonesia. Dengan demikian maka keberadaan Divhubinter Polri sendiri tidak dapat lepas dari sejarah terbentuknya NCB INTERPOL Indonesia.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      1. Periode Seksi INTERPOL 1952 - 1955
                    </p>
                    <p className="indent-8 pt-4">
                      Pembentukan NCB-INTERPOL Indonesia secara yuridis, didasarkan pada Konstitusi ICPO-INTERPOL pasal 32 yang menyatakan bahwa setiap negara anggota harus menunjuk suatu badan yang berfungsi sebagai National Central Bureau/ NCB (Biro Pusat Nasional) guna menjamin hubungan dengan berbagai departemen/instansi pemerintah di dalam negeri, NCB-NCB negara lain dan Sekretariat Jenderal ICPO-INTERPOL.
                    </p>
                    <p className="indent-8 pt-4">
                      Pada tahun 1952 Pemerintah Indonesia mengirim 2 (dua) orang utusan sebagai peninjau pada Sidang Umum ICPO-INTERPOL ke-21 di Stockholm, Swedia. Pada tahun 1954, Indonesia resmi diterima menjadi anggota ICPO-INTERPOL. Pada periode 1952-1954 ini, Pemerintah Indonesia belum menunjuk suatu badan tertentu yang berfungsi sebagai NCB Indonesia.
                    </p>
                    <p className="indent-8 pt-4">
                      Seluruh permasalahan yang menyangkut tugas-tugas NCB Indonesia dilaksanakan oleh Kantor Perdana Menteri Indonesia. Baru pada akhir tahun 1954, dengan Surat Keputusan Perdana Menteri Republik Indonesia No. 245/PM/1954 tanggal 5 Oktober 1954 Pemerintah Republik Indonesia menunjuk Jawatan Kepolisian Negara sebagai NCB Indonesia untuk mewakili Pemerintah Indonesia dalam organisasi ICPO-INTERPOL dan sebagai Kepala NCB Indonesia ditunjuk Kepala Kepolisian Negara. Untuk menindaklanjuti Keputusan Perdana Menteri Republik Indonesia tersebut, maka terbentuklan Seksi INTERPOL pada Dinas Reserse Kriminil sesuai dengan Order Kepala Kepolisian Negara No. 1/VIII/1954 No. Pol. : I/I/7/Sek tanggal 15 Oktober 1954.
                    </p>
                  </div>
                
                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      2. Periode NCB Indonesia 1955 -1960
                    </p>
                    <p className="indent-8 pt-4">
                      Pada periode 1955-1960, kejahatan internasional telah berkembang dan sudah tidak mengenal batas negara. Menghadapi masalah tersebut pada tahun 1956 Pimpinan Jawatan Kepolisian Negara menganggap perlu adanya perubahan status NCB Indonesia, sehingga akhirnya dikeluarkan Order Kepala Kepolisian Negara No. 25/I/1956 yang melepaskan Seksi INTERPOL dari Dinas Reserse Kriminil menjadi NCB Indonesia yang berada langsung dibawah Kepala Kepolisian Negara.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      3. Periode Dinas INTERPOL 1961 - 1966
                    </p>
                    <p className="indent-8 pt-4">
                      Iklim Politik di Indonesia setelah tahun 1960 kurang menguntungkan untuk NCB Indonesia, sebagai akibat keluarnya Indonesia dari keanggotaan PBB. Sekalipun demikian, secara resmi NCB Indonesia tidak pernah memutuskan hubungan dengan Paris sebagai Pusat Organisasi. Pimpinan Kepolisian saat itu menempuh kebijaksanaan guna menyesuaikan diri dengan kondisi politik, dengan dikeluarkannya Order Direktur Reserse/Depak No. 9/UM/1964 tanggal 14 April 1964 yang menetapkan bahwa untuk sementara waktu NCB Indonesia sebagai Dinas INTERPOL dibawah Direktur Reserse Kriminal Depak.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      4. Periode Biro INTERPOL 1967 - 1968
                    </p>
                    <p className="indent-8 pt-4">
                    Pada tahun 1967 NCB Indonesia berada langsung dibawah Menteri/Pangak, berdasarkan Surat Keputusan Menteri/Pangak No. Pol.: 92/SK/Menpangak/1967 tanggal 26 Juni 1967. Belum sempat SK Menteri/Pangak tersebut dilaksanakan, telah keluar Peraturan Menteri/Pangak No. 5/Prt/MP/1967 tanggal 1 Juli 1967 yang menentukan bahwa pada Markas Besar Angkatan Kepolisian dibentuk Biro INTERPOL.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      5. Periode Sekretariat NCB Indonesia 1969 - 1975
                    </p>
                    <p className="indent-8 pt-4">
                      Pada tahun 1969 dengan Surat Keputusan Pangak No. Pol. : 21/SK/Pangak/1969 tanggal 17 Februari 1969 dibentuk Sekretariat NCB Indonesia yang berada dibawah Komandan Jenderal Komando Reserse.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      6. Periode Bakersinpol 1976 - 1983
                    </p>
                    <p className="indent-8 pt-4">
                      Sesuai dengan Keputusan Menhankam Pangab No. Kep/15/IV/1976 tentang Pokok-pokok Organisasi dan Prosedur Kepolisian Negara RI dan Surat Keputusan Kapolri No. Pol. : Skep/50/VII/1977 tanggal 1 Juli 1977 tentang Pokok-pokok Organisasi dan prosedur Polri, dibentuk Badan Kerjasama Internasional Kepolisian RI pada tingkat Mabes Polri (Bakersinpol) sebagai Badan Pelaksanan Pusat yang berada langsung dibawah Kapolri.
                    </p>
                    <p className="indent-8 pt-4">
                      Sesuai dengan Keputusan Kapolri tersebut, Bakersinpol adalah merupakan badan yang melaksanakan fungsi NCB Indonesia ditambah dengan tugas-tugas hubungan luar negeri pada umumnya.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      7. Periode Sekretariat NCB – INTERPOL 1984 -1996
                    </p>
                    <p className="indent-8 pt-4">
                      Pada tahun 1984 dengan Keputusan Pangab No. Kep/11/P/III/1984 tanggal 31 Maret 1984 tentang Pokok-pokok Organisasi dan Prosedur Kepolisian Negara RI, Organisasi Polri mengalami perubahan. Demikian juga dengan Bakersinpol berubah menjadi Sekretariat NCB-INTERPOL yang berkedudukan di bawah Kapolri yang bertujuan membina, menyelenggarakan dan melaksanakan fungsi INTERPOL di Indonesia.
                    </p>
                    <p className="indent-8 pt-4">
                      Pada tahun 1992 sesuai dengan Keputusan Pangab No. Kep/11/X/1992 tanggal 5 Oktober 1992, jabatan Kepala Sekretariat NCB-INTERPOL (Kaset NCB-INTERPOL) diubah menjadi Sekretaris NCB-INTERPOL (Ses NCB-INTERPOL).
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      8. Periode Sekretariat NCB – INTERPOL Indonesia 1997 - 2009
                    </p>
                    <p className="indent-8 pt-4">
                      Pada tahun 1997 sesuai dengan Keputusan Pangab No. Kep/09/X/1997 tanggal 10 Oktober 1997 tentang Pokok-pokok Organisasi dan Prosedur Polri, nama Sekretariat NCB-INTERPOL ditambah dengan kata “Indonesia” sehingga sebutan organisasi menjadi Sekretariat NCB-INTERPOL Indonesia (Set NCB-INTERPOL Indonesia) dan sebutan jabatannya menjadi Sekretaris NCB-INTERPOL Indonesia (Ses NCB-INTERPOL Indonesia).
                    </p>
                    <p className="indent-8 pt-4">
                      Berdasarkan Lampiran “J” Keputusan Kapolri No. Pol. Kep/53/X/2002 tanggal 17 Oktober 2002 tentang Organisasi dan Tata Kerja Set NCB-INTERPOL Indonesia, tugas Set NCB-INTERPOL Indonesia selain bertugas menyelenggarakan kerjasama/ koordinasi melalui wadah ICPO-INTERPOL dalam rangka mendukung upaya penanggulangan kejahatan internasional/ transnational ditambah dengan tugas lain di luar bidang INTERPOL yaitu menyelenggarakan kerjasama internasional/ antar negara dalam rangka mendukung pengembangan Polri dan kegiatan “Peacekeeping operation” di bawah bendera PBB.
                    </p>
                    <p className="indent-8 pt-4">
                      Pada tahun 2008, Sekretariat NCB-INTERPOL Indonesia yang sebelumnya membawahi 4 (empat) bidang yaitu bidang INTERPOL, bidang Kerjasama Internasional, bidang Hubungan Antar Lembaga dan bidang Komunikasi Internasional, telah dikembangkan dengan membawahi 6 (enam) bidang yaitu bidang INTERPOL, bidang Konvensi Internasional, bidang Protokol, bidang LO dan Perbatasan, bidang Kerjasama Pendidikan dan Misi Kepolisian, serta bidang Komunikasi Internasional.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      9. Periode Divhubinter Polri 2010 - sekarang
                    </p>
                    <p className="indent-8 pt-4">
                      Dengan adanya reformasi ditubuh Polri dan semakin besarnya beban tugas Sekretariat NCB-INTERPOL Indonesia dalam kerjasama internasional Polri yang tidak hanya menangani kerjasama dalam penanggulangan kejahatan internasional/ transnasional, tetapi juga dalam meningkatkan kemampuan kapasitas sumber daya manusia dan sarana prasarana serta tugas misi kemanusiaan dan perdamaian, maka berdasarkan Peraturan Kapolri nomor 21 tahun 2010 organisasi ini dikembangkan menjadi Divisi Hubungan Internasional (Divhubinter) Polri yang terbagi dalam 2 (dua) biro yaitu Sekretariat NCB-INTERPOL Indonesia dan Biro Misi Internasional.
                    </p>
                    <p className="indent-8 pt-4">
                      Namun demikian jabatan Kepala NCB-INTERPOL Indonesia tetap diemban oleh Kapolri, sedangkan pelaksana harian NCB-INTERPOL Indonesia diemban oleh Kepala Divhubinter Polri. Divhubinter Polri menjadi “one gate system” Polri dalam kerja sama internasional bidang kepolisian, baik dalam penanggulangan kejahatan internasional/transnasional, pengembangan kapasitas, maupun misi internasional/misi kemanusiaan.
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ProfilContent;