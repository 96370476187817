"use client";

import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Disclosure } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import SocialMedia from "./SocialMedia";
import DesktopHeaderNavigation from "./DesktopHeaderNavigation";
import { navigation } from "./NavigationConfig";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { classNames } from "../../utilities/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import MobileHeaderNavigation from "./MobileHeaderNavigation";

type MainHeaderProps = {
  transparent?: boolean;
  backgroundColorRGB?: string;
  initialTextColor?: string;
  afterScrollTextColor?: string;
  theme: string;
  toggleTheme: () => void;
};

const MainHeader: React.FC<MainHeaderProps> = ({
  transparent,
  backgroundColorRGB = "255, 255, 255",
  toggleTheme,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [scrollOpacity, setScrollOpacity] = useState(0);
  const { isMdScreen } = useWindowWidth();
  const opacityThreshold = !isMdScreen ? 20 : 100;
  const [isOpen, setIsOpen] = useState(false);

  const backgroundColorStyle = useMemo(() => {
    let style: CSSProperties = {};
    if (transparent) {
      style = {
        ...style,
        backgroundColor: `rgba(${backgroundColorRGB}, ${scrollOpacity})`,
        transition: "linear 0.075s",
      };
    } else {
      style = {
        ...style,
        backgroundColor: `rgba(${backgroundColorRGB}, 1)`,
      };
    }
    return style;
  }, [transparent, backgroundColorRGB, scrollOpacity]);

  const _onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const target = e.target as HTMLFormElement;
      const searchInput = target.search as HTMLInputElement;
      setSearch(searchInput.value);
      navigate("berita/" + search);
      // window.location.reload();
    },
    [navigate, search],
  );

  useEffect(() => {
    if (transparent) {
      const handleScroll = () => {
        const scrollTop = window ? window.scrollY : 0;
        const opacity = Math.min(scrollTop / opacityThreshold, 1);
        setScrollOpacity(Math.max(0, opacity));
      };

      handleScroll(); // Set initial scroll opacity

      if (window) {
        window.addEventListener("scroll", handleScroll);
        return () => {
          if (window) {
            window.removeEventListener("scroll", handleScroll);
          }
        };
      }
    }
  }, [transparent, opacityThreshold]);

  useEffect(() => {
    // Close the Disclosure when the URL changes
    setIsOpen(false);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={classNames(transparent ? "" : "h-[64px]")}>
      <Disclosure
        as="nav"
        className="fixed left-0 top-0 z-50 w-full"
        style={backgroundColorStyle}
      >
        <>
          <div className="mx-auto px-2 sm:px-4 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-10 w-auto shadow outline-1 outline-white"
                    src="/logo_dhi.png"
                    alt="Your Company"
                  />
                </div>
                <div className="hidden flex-1 lg:ml-6 lg:flex lg:space-x-8">
                  <DesktopHeaderNavigation navigation={navigation} />
                </div>
              </div>
              <div className="flex flex-1 items-center justify-center space-x-4 px-2 lg:ml-6 lg:justify-end">
                <SocialMedia />
                <div className="w-full max-w-lg lg:max-w-xs">
                  <label htmlFor="search" className="sr-only">
                    Cari berita
                  </label>
                  <div className="relative">
                    <form onSubmit={_onSubmit}>
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Cari berita"
                        type="search"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        value={search}
                      />
                    </form>
                  </div>
                </div>
                <ThemeToggler toggleTheme={toggleTheme} />
              </div>
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button
                  onClick={() => setIsOpen(!isOpen)}
                  className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {isOpen ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel static className="lg:hidden">
            {isOpen ? (
              <div className="space-y-1 pb-3 pt-2 px-4">
                <ThemeToggler
                  toggleTheme={toggleTheme}
                  className="!flex md:!hidden justify-end -mr-6"
                />
                <MobileHeaderNavigation navigation={navigation} />
              </div>
            ) : (
              <></>
            )}
          </Disclosure.Panel>
        </>
      </Disclosure>
    </div>
  );
};

const ThemeToggler: React.FC<{
  toggleTheme: () => void;
  className?: string;
}> = ({ toggleTheme, className = "" }) => {
  return (
    <div
      className={classNames(
        "items-center space-x-5 mr-2 hidden md:flex",
        className,
      )}
    >
      <div className="flex">
        {/* Toggle Dark Mode */}
        <FontAwesomeIcon
          icon={["far", "moon"]}
          className="text-blue-400 w-5 h-5 ml-4 mr-2"
        />
        <input type="checkbox" className="toggle" onClick={toggleTheme}></input>
        <FontAwesomeIcon
          icon={["far", "sun"]}
          className="text-blue-400 w-5 h-5 ml-2 mr-6"
        />
      </div>
    </div>
  );
};

export default MainHeader;
