import { useState } from "react";
import axios from "axios";

const KontakContent = () => {
  return (
    <div>
      <div className="container mx-auto px-12">
        <div className=" w-full items-center">
          <h2 className="text-4xl font-bold text-center uppercase pt-32">
            Kontak Kami
          </h2>
          <div className="flex flex-col layout py-10 mt-10 gap-6 ">
            <div className="grid phone:flex-col laptop:grid-cols-2 gap-8">
                  <div className="flex flex-col h-full bg-neutral rounded-lg gap-4 p-8">
                      <div className="flex flex-col gap-4">
                          <div className="flex font-sf-bold text-xl">
                              Informasi Kontak
                          </div>
                          <div className="flex flex-col">
                              <label className="block text-base leading-6">
                                  Alamat:
                              </label>
                              <div className="text-base">
                                  Jln. Trunojoyo 3 Gedung TNCC Lt.11 Mabes Polri Jakarta, Indonesia
                              </div>
                          </div>
                          <div className="flex flex-col">
                              <label className="block text-base leading-6">
                                  Email:
                              </label>
                              <div className="text-base">
                                  ncb-jakarta@interpol.go.id
                              </div>
                          </div>
                          <div className="flex flex-col">
                              <label className="block text-base leading-6">
                                  Telepon:
                              </label>
                              <div className="text-base">
                                  021 721 846 7
                              </div>
                          </div>
                          <div className="flex flex-col">
                              <label className="block text-base leading-6">
                                  Fax:
                              </label>
                              <div className="text-base">
                                  021 720 140 2
                              </div>
                              <div className="text-base">
                                  021 726 920 3
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="flex flex-col h-full bg-neutral rounded-lg p-8">
                      <div className="flex flex-col gap-4">
                          <div className="flex font-sf-bold text-xl">
                              Tanya Kami
                          </div>
                          <div className="flex flex-col gap-3 mt-3">
                              <div className="flex flex-col">
                                  <label
                                      className="block text-sm leading-6 text-gray-900"
                                  >
                                      Nama Lengkap
                                  </label>
                                  <div className="mt-2">
                                      <input
                                      type="text"
                                      name="name"
                                      id="name"
                                      autoComplete="given-name"
                                      className="block w-full text-black rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                                      />
                                  </div>
                              </div>
                              <div className="flex flex-col">
                                  <label
                                      className="block text-sm leading-6 text-gray-900"
                                  >
                                      Email
                                  </label>
                                  <div className="mt-2">
                                      <input
                                      type="text"
                                      name="email"
                                      id="email"
                                      autoComplete="given-name"
                                      className="block w-full text-black rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                                      />
                                  </div>
                              </div>
                              <div className="flex flex-col">
                                  <label className="text-sm">Pertanyaan</label>
                                  <textarea id="komentar" className="block w-full mt-2 text-black h-20 rounded-md border-0 px-3 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"></textarea>
                              </div>
                              <div className="flex justify-end mt-3">
                                  <div className="btn btn-success flex justify-center items-center h-12 w-28 rounded-lg">Kirim</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KontakContent;
