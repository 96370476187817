import { createContext, useContext } from "react";
import { Theme } from "../../definitions/enum";

export const ThemeProviderContext = createContext<Theme>(Theme.LIGHT);

export const useTheme = () => {
  const theme = useContext(ThemeProviderContext);
  if (theme === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return theme;
};
