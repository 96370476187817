import MisiPBBContent from "../components/layouts/misi_internasional/MisiPBBContent";

const MisiPBB = () => {
  return (
    <div>
      <MisiPBBContent />
    </div>
  );
};

export default MisiPBB;
