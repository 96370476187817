import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card02 from "./Card/Card02";
import axios from "axios";
import React, { useState, useEffect } from "react";
// import Card03 from "./Card/Card03";

const NewsEvent = () => {
  const [latestarticles, setLatestArticles] = useState([]);
  useEffect(() => {
    const getLatestArticles = async () => {
      const response = await axios.get(
        "https://divhubinter.polri.go.id/api/dhi_home",
      );
      setLatestArticles(response.data.event);
      // alert("irfan ganteng");
    };

    getLatestArticles();
  }, []);

  return (
    <div className="card w-full bg-neutral text-neutral-content shadow-xl p-6">
      <div className="flex justify-left items-center">
        <FontAwesomeIcon
          icon={["fas", "newspaper"]}
          className="text-blue-400 w-5 h-5"
        />
        <h2 className="text-2xl font-bold pl-2">Latest Event</h2>
      </div>

      <hr className="divider"></hr>
      <Card02 newsLatest={latestarticles} />
    </div>
  );
};

export default NewsEvent;
