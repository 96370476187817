import NewsLatestSection from "../home/NewsLatestSection";

const StrukturOrganisasiContent = () => {
  return (
    <div className="container mx-auto py-12">
      <div className="card bg-neutral shadow-xl">
        <div className="card-body">
          <br />
          <p className=" text-left  pb-4 ">
            Tentang Kami / Struktur Organisasi
          </p>
          <h1 className="text-4xl font-bold">Struktur Organisasi</h1>
          <br />
          <figure>
            <img src="/static/strukor.jpg" alt="Struktur Organisasi" />
          </figure>

          <div className="card-actions justify-end"></div>
        </div>
      </div>
    </div>
  );
};

export default StrukturOrganisasiContent;
