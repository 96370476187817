import NewsDetailContent from "../components/layouts/berita_detail/NewsDetailContent";

const NewsDetail = () => {
  return (
    <div>
      <NewsDetailContent />
    </div>
  );
};

export default NewsDetail;
