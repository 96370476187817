import NewsLatestSection from "../home/NewsLatestSection";

const VisiMisiContent = () => {
  return (
    <div>
      <div className="mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl">
        <div className="flex flex-row my-12">
          <div className="basis-full p-4">
            <div className="card w-full bg-neutral text-neutral-content shadow-xl border-t-8 border-blue-600 ... rounded-none">
              <div className="card-body p-0">
                <div className="flex flex-row px-6 pt-6">
                  <div className="grow">
                    <h2 className="card-title text-2xl font-bold">
                      Visi & Misi
                    </h2>
                  </div>
                  <div className="shrink">
                    <div className="text-sm breadcrumbs">
                      <ul>
                        <li>Tentang</li>
                        <li>
                          <a href="/visi-misi">Visi & Misi</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="divider m-0"></div>

                <div className="mb-8">
                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      Visi Divisi Hubungan Internasional Polri:
                    </p>
                    <p className="indent-8 pt-4">
                      Terwujudnya kerja sama kepolisian, penegakan hukum dan
                      misi internasional serta memberikan perlindungan dan
                      pelayanan WNI / WNA baik di dalam maupun di luar negeri
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      Misi Divisi Hubungan Internasional Polri:
                    </p>
                    <ul className="list-outside list-disc pl-8 ...">
                      <li>
                        melaksanakan kerja sama internasional dengan organisasi
                        pemerintah dan organisasi non pemerintah baik secara
                        bilateral maupun multilateral
                      </li>
                      <li>
                        melaksanakan kerja sama dengan kepolisian negara sesama
                        anggota ICPO-INTERPOL dan ASEANAPOL dalam upaya
                        memonitor, mencegah dan memberantas kejahatan
                        transnasional dan internasional
                      </li>
                      <li>
                        membantu dan bekerja sama dengan perwakilan Republik
                        Indonesia dan lembaga terkait di luar negeri dalam
                        memberikan perlindungan dan pelayanan kepada warga
                        negara Indonesia
                      </li>
                      <li>
                        melaksanakan kerja sama internasional dalam upaya turut
                        serta menjaga perdamaian dunia
                      </li>
                      <li>
                        melaksanakan pengembangan kapasitas baik sumber daya
                        manusia maupun sarana dan prasarana
                      </li>
                      <li>
                        melaksanakan kerja sama dan koordinasi pengamanan
                        wilayah perbatasan dengan negara yang berbatasan
                        langsung
                      </li>
                      <li>
                        melaksanakan kegiatan protokoler dan f3r0zistrasi
                        perjalanan dinas ke luar negeri
                      </li>
                      <li>
                        melaksanakan pertukaran informasi dan komunikasi
                        internasional melalui pemanfaatan jaringan INTERPOL,
                        ASEANAPOL dan jaringan komunikasi lainnya
                      </li>
                      <li>
                        melaksanakan pertemuan dan kesepakatan internasional
                        baik yang bersifat bilateral maupun multilateral
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="basis-1/3 p-4">

              <div className="basis-1/1 mb-4">
                <NewsLatestSection />
              </div>

          </div> */}
        </div>
      </div>
    </div>
  );
};

export default VisiMisiContent;
