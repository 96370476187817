import { Link } from "react-router-dom";

function Card09(props) {
    const { MediaReleaseHighlights } = props;
    return (
        <div>
            <div className="grid grid-cols-3 gap-8">
                {MediaReleaseHighlights.map((MediaReleaseItem, index) => (
                <div key={index}>
                    <div className="card card-compact h-full bg-neutral text-neutral-content shadow-xl">
                    <figure className="aspect-[4/3] bg-cover">
                        <img src={`https://divhubinter.polri.go.id/` + MediaReleaseItem.artikel_thumbnail} 
                        alt="Null" 
                        className="w-full h-full"
                        />
                    </figure>
                    <div className="card-body justify-between">
                        <h3>{MediaReleaseItem.artikel_jenis}</h3>
                        <h2 className="card-title">
                        <Link to={"/newsdetail/" + MediaReleaseItem.artikel_id}>
                            {MediaReleaseItem.artikel_judul}
                        </Link>
                        </h2>
                        <div className="flex w-full">
                        <Link to={"/newsdetail/" + MediaReleaseItem.artikel_id}>
                            <button className="btn btn-primary">View</button>
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    );
}

export default Card09;
