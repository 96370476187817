import NewsLatestSection from "../home/NewsLatestSection";

const ProfilMisiIntContent = () => {
  return (
    <div>
      <div className="mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl">
        <div className="flex flex-row my-12">
          <div className="p-4">
            <div className="card w-full bg-neutral text-neutral-content shadow-xl border-t-8 border-blue-600 ... rounded-none">
              <div className="card-body p-0">
                <div className="flex flex-row px-6 pt-6">
                  <div className="grow">
                    <h2 className="card-title text-2xl font-bold">
                      Profil Misi Internasional
                    </h2>
                  </div>
                  <div className="shrink">
                    <div className="text-sm breadcrumbs">
                      <ul>
                        <li>Misi Internasional</li>
                        <li>
                          <a href="/visi-misi">Profil Misi Internasional</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="divider m-0"></div>

                <div className="mb-8">
                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">BIRO MISI INTERNASIONAL</p>
                    <p className="indent-8 pt-4">
                      Biro Misi Internasional (Romisinter) merupakan biro baru
                      di lingkungan Polri yang dibentuk berdasarkan Peraturan
                      Kapolri Nomor 21 Tahun 2010 pasal 23 tentang Organisasi
                      dan Tata Kerja tingkat Mabes Polri dengan dibentuknya
                      struktur baru pada Divhubinter Polri yang bertanggung
                      jawab atas terselenggaranya misi Pemeliharaan Perdamaian
                      Dunia yang dilaksanakan oleh Polri pada tugas misi
                      internasional baik sebagai satuan tugas Formed Police Unit
                      (FPU) maupun sebagai Police Adviser (PA) ke daerah
                      penugasan di seluruh dunia berdasarkan kebutuhan yang
                      diteruskan oleh Markas Besar PBB di New York.
                    </p>
                    <p className="indent-8 pt-4">
                      Pembentukan Romisinter juga dilatarbelakangi adanya
                      harapan agar kerjasama pengembangan kapasitas personel
                      Polri dengan aktor-aktor dari manca negara dapat dilakukan
                      melalui satu pintu (one gate system) mengingat selama ini
                      kerjasama tersebut secara tersebar dan sektoral masih
                      dilakukan oleh 10 (sepuluh) satuan kerja di lingkungan
                      Mabes Polri. Dengan adanya Romisinter diharapkan kerjasama
                      pengembangan kapasitas di lingkungan Mabes Polri dapat
                      lebih terkoordinasi secara optimal agar dengan demikian
                      dapat terhindar dari terjadinya tumpang tindih kewenangan
                      (overlap), memudahkan dilakukannya pendataan, evaluasi dan
                      perencanaan, serta tepat dalam penetapan personel maupun
                      jenis kerjasama yang dibutuhkan.
                    </p>
                    <p className="indent-8 pt-4">
                      Keberadaan UU Kepolisian Nomor 2 Tahun 2002 tentang
                      Kepolisian Negara Republik Indonesia juga menjadi latar
                      belakang pembentukan Romisinter dimaksud yang terkait
                      dengan partisipasi Polri dalam misi pemeliharaan
                      perdamaian dunia sebagaimana disebutkan dalam pasal 41
                      ayat 3 yang berbunyi “Kepolisian Negara Republik Indonesia
                      membantu secara aktif tugas pemeliharaan perdamaian dunia
                      di bawah bendera Perserikatan Bangsa-Bangsa”.
                    </p>
                    <p className="indent-8 pt-4">
                      Dimasa mendatang, telah diprediksi bahwa situasi keamanan
                      dunia akan terus membaik namun konflik yang terjadi akan
                      semakin kompleks dari aspek penyebabnya. Dalam kaitan ini
                      PBB telah menegaskan bahwa misi perdamaian PBB tidak lagi
                      hanya sebatas pada misi menciptakan perdamaian antar
                      pihak-pihak yang terlibat konflik (peacekeeping), namun
                      secara lebih luas mengupayakan tercapainya rekonsiliasi
                      dan perdamaian yang berkelanjutan diantara pihak-pihak
                      yang berseteru dan akan ditindaklanjuti melalui suatu
                      proses dimana aspek politis, keamanan dan pembangunan
                      harus dilakukan secara serentak (peacebuilding).
                    </p>
                    <p className="indent-8 pt-4">
                      Tugas-tugas pada peacebuilding akan mencakup seluruh aspek
                      yang dilakukan PBB melalui inisiatif-inisiatif seperti
                      diantaranya adalah reintegrasi mantan kombatan kedalam
                      masyarakat sipil, penghormatan terhadap nilai-nilai HAM,
                      memberikan asistensi teknis terhadap pembangunan supremasi
                      hukum, reformasi hukum dan pembangunan demokrasi serta
                      asistensi terhadap penyelesaian konflik.
                    </p>
                    <p className="indent-8 pt-4">
                      Untuk dapat mengemban misi dimaksud maka pada masa
                      mendatang akan dibutuhkan lebih banyak personel polisi
                      termasuk Polri yang memiliki tugas dan peran untuk
                      tugas-tugas dimaksud. Dalam kaitan itulah diperlukan
                      Romisinter agar penyelenggaraan misi-misi pemeliharaan
                      perdamaian maupun misi-misi kemanusiaan lainnya dapat
                      dilakukan lebih baik. Adapun tugas dan peran dari Biro
                      Misi Internasional adalah membina, mengawasi dan
                      mengendalikan penyelenggaraan tugas misi internasional
                      meliputi misi perdamaian, misi kemanusiaan dan misi
                      pembangunan kapasitas Polri.
                    </p>
                    <p className="indent-8 pt-4">
                      Biro Misi Internasional (Romisinter) dalam pelaksanaan
                      tugas dan fungsinya dibantu oleh 2 bagian, yakni Bagian
                      Perdamaian dan Kemanusiaan (Bagdamkeman) dan Bagian
                      Pengembangan Kapasitas (Bagkembangtas).
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      A. Bagian Misi Perdamaian dan Kemanusiaan (BAGDAMKEMAN)
                    </p>
                    <p className="pt-4 font-bold">
                      1. Tugas pokok, fungsi, dan peran
                    </p>
                    <p className="indent-8 pt-4">
                      Bagdamkeman bertugas melaksanakan misi perdamaian dan misi
                      kemanusiaan non-konflik dalam wadah organisasi
                      internasional, dimana didalamnya meliputi kegiatan
                      perencanaan, penyiapan personel, sarana prasarana,
                      pembekalan pendidikan dan pelatihan, pengiriman,
                      pengakhiran tugas, monitoring dan evaluasi serta
                      koordinasi dengan instansi terkait.
                    </p>
                    <p className="pt-4">
                      Dalam melaksanakan tugas, Bagdamkeman dibantu oleh:
                    </p>
                    <ul className="list-outside list-decimal pl-8 ...">
                      <li>Subbagrengiat</li>

                      <li>Subbagbeklat</li>

                      <li>Subbagmonev</li>
                    </ul>

                    <p className="pt-4 font-bold">
                      2. Operasi Misi Perdamaian PBB
                    </p>

                    <p className="indent-8 pt-4">
                      Misi Pemeliharaan Perdamaian (MPP) merupakan “flagship
                      enterprise” dari PBB dalam rangka turut menjaga perdamaian
                      dan keamanan internasional. Saat ini tercatat lebih dari
                      122.000 personel baik militer, polisi, maupun sipil yang
                      berpartisipasi di 15 misi. Saat ini, PBB menghadapi
                      tantangan dalam menutup gap antara supply dan demand dalam
                      berbagai MPP.
                    </p>
                    <p className="indent-8 pt-4">
                      Pada awalnya peran MPP PBB hanya terbatas pada
                      pemeliharaan gencatan senjata dan stabilisasi situasi di
                      lapangan sehingga usaha-usaha politik untuk menyelesaikan
                      konflik dapat dilakukan. Namun demikian, dengan
                      berakhirnya perang dingin, konteks penggelaran MPP PBB
                      juga berubah dari misi “tradisional” yang mengedepankan
                      tugas-tugas militer, menjadi misi yang lebih
                      “multidimensional“ dalam rangka mengimplementasikan
                      perjanjian damai secara komprehensif dan membantu
                      meletakkan dasar-dasar bagi terciptanya perdamaian yang
                      berkelanjutan.
                    </p>
                    <p className="indent-8 pt-4">
                      Sifat dari konflik yang harus dihadapi oleh MPP PBB juga
                      mengalami perubahan. Sebelumnya MPP PBB harus menghadapi
                      konflik antar negara namun saat ini MPP PBB dituntut pula
                      untuk dapat diterjunkan pada berbagai konflik internal dan
                      perang saudara.
                    </p>
                    <p className="indent-8 pt-4">
                      Partisipasi Polri dalam Misi Pemeliharaan Perdamaian PBB
                      (Peacekeeping Operations) didasari oleh semangat Pembukaan
                      UUD 1945, khususnya Alinea IV, tentang komitmen Indonesia
                      untuk ikut melaksanakan ketertiban dunia yang berdasarkan
                      kemerdekaan, perdamaian abadi dan keadilan sosial. Selain
                      itu komitmen tersebut juga tercerminkan di dalam kebijakan
                      politik luar negeri Indonesia, khususnya dalam hal upaya
                      Indonesia untuk turut berperan aktif menjaga keamanan dan
                      perdamaian internasional. Indonesia memiliki pandangan
                      bahwa keberhasilan dari suatu misi perdamaian sangat
                      bergantung kepada prinsip-prinsip yang telah disepakati
                      oleh seluruh anggota PBB, yaitu: persetujuan dari
                      pihak-pihak yang bertikai (consent), memiliki mandat yang
                      jelas, impartiality, dan non-use of force kecuali untuk
                      membela diri dan mempertahan mandat yang diemban dari PBB.
                      Ukuran keberhasilan suatu misi perdamaian dapat dilihat
                      dari kondisi negara yang tengah dilanda konflik. Kehadiran
                      misi perdamaian seharusnya dapat mencegah terjadinya
                      kembali konflik.
                    </p>
                    <p className="indent-8 pt-4">
                      Indonesia memiliki visi untuk lebih mengembangkan peran
                      dan partisipasinya di dalam Peacekeeping Operations
                      (PKO)/Misi Pemeliharaan Perdamaian (MPP), khususnya
                      meningkatkan peran polisi yang leading sector
                      pengembangannya telah dilakukan oleh Mabes Polri pada
                      bagian perdamaian dan kemanusiaan (Bagdamkeman) yang akan
                      tetap menjadi flagship keterlibatan dan peran serta
                      Indonesia di dalam berbagai misi perdamaian PBB.
                    </p>
                    <p className="indent-8 pt-4">
                      Sehubungan dengan perkembangan dimaksud, maka untuk dapat
                      menjawab tantangan kedepan dibidang penyelenggaraan Misi
                      Pemeliharaan Perdamaian Dunia, Polri tengah mewacanakan
                      untuk menyiapkan Pusat Pelatihan Misi Pemeliharaan
                      Perdamaian untuk kepolisian dari negara-negara ASEAN
                      (ASEANAPOL Peacekeeping Training Center) yang terletak di
                      pusat pelatihan multifungsi Polri Cikeas, Bogor.
                      Diharapkan tempat tersebut dapat menjadi Pusat Pelatihan
                      bagi personel polisi yang akan ditugaskan ke daerah misi
                      pemeliharaan PBB untuk kawasan Asia Tenggara khususnya dan
                      kawasan Asia Pasifik pada umumnya.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      A. Bagian Misi Perdamaian dan Kemanusiaan (BAGDAMKEMAN)
                    </p>
                    <p className="indent-8 pt-4">
                      Dalam Perkap Nomor 21 Tahun 2010 dibentuk suatu unsur
                      pelaksana baru yang bertugas secara khusus menangani
                      bidang peningkatan dan pengembangan kapasitas bagi
                      personel Polri baik dengan negara luar maupun dengan
                      institusi dalam negeri dalam bentuk pendidikan dan
                      pelatihan, peningkatan sarana dan prasarana serta
                      pertukaran personel. Hal ini diharapkan terwujudnya postur
                      Polri yang profesional serta memiliki wawasan
                      internasional.
                    </p>
                    <p className="pt-4 font-bold">
                      Tugas pokok, fungsi, dan peran
                    </p>
                    <p className="indent-8 pt-4">
                      Bagian Pengembangan Kapasitas (Bagkembangtas) Biro Misi
                      Internasional mempunyai tugas melaksanakan kerja sama
                      internasional dalam bentuk merintis, menjalin dan
                      meningkatkan hubungan dengan kedutaan dan atase kepolisian
                      negara akreditasi di Indonesia dan lembaga pemerintah,
                      Kepolisian serta Lembaga Non Pemerintah baik di dalam dan
                      di luar negeri dalam rangka meningkatkan kemampuan SDM
                      Polri dan pembangunan sarana prasarana Polri dalam
                      kaitannya dengan tugas internasional dan pengembangan
                      kapasitas lainnya di Luar negeri.
                    </p>
                    <p className="indent-8 pt-4">
                      Bagkembangtas menyelenggarakan fungsi persiapan dan
                      pelatihan personel Polri yang akan melaksanakan pendidikan
                      dan pelatihan di luar negeri, pemberian dukungan
                      pendidikan dan pelatihan dalam membangun kapasitas SDM dan
                      sarana dan prasarana, penjajakan kepada kepolisian negara
                      akreditasi, guna mencari peluang kerja sama di bidang
                      pendidikan, pelatihan dan bantuan peralatan yang sesuai
                      dengan kebutuhan.
                    </p>

                    <p className="pt-4">
                      Dalam melaksanakan tugas, Bagkembangtas dibantu oleh:
                    </p>
                    <ul className="list-outside list-decimal pl-8 ...">
                      <li>Subbagbangtas</li>

                      <li>Subbagdiklat</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilMisiIntContent;
