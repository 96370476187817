import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  NavigationItem,
  NavigationItemWithSubItems,
  RootNavigationItem,
} from "./NavigationConfig";
import { Transition } from "@headlessui/react";
import { classNames } from "../../utilities/common";
import IcChevronDownSVG from "../IcChevronDownSVG";

type Props = {
  navigation: NavigationItem[];
};

const WrapperLink = ({
  to,
  children,
  className,
  isServerNavigation,
}: {
  to: string;
  children: React.ReactNode;
  className?: string;
  isServerNavigation?: boolean;
}) => {
  if (isServerNavigation) {
    return (
      <a
        href={to}
        className={className}
        target="_self"
        onClick={(e) => {
          if (window?.location?.href) window.location.href = to;
          e.preventDefault();
        }}
      >
        {children}
      </a>
    );
  }
  return (
    <Link to={to} className={className} role="link" target="_self">
      {children}
    </Link>
  );
};

const DesktopHeaderNavigationItemWithSubItems = ({
  navigationItem,
}: {
  navigationItem: NavigationItemWithSubItems;
}) => {
  const location = useLocation();
  const pathname = location.pathname;
  const ref = useRef<HTMLSpanElement>(null);
  const [isShowSubItems, setIsShowSubItems] = useState(false);
  const isRootActive = navigationItem.subItems?.some(
    (subItem) => subItem.href === pathname,
  );

  useEffect(() => {
    // if user click outside navigation item, close sub items
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsShowSubItems(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <span
        ref={ref}
        className="h-full"
        onMouseEnter={() => setIsShowSubItems(true)}
        onMouseLeave={() => setIsShowSubItems(false)}
      >
        <span
          className={classNames(
            "flex h-full cursor-pointer flex-row items-center justify-center hover:underline",
            isRootActive
              ? "border-earth-green-60 font-medium text-[#d8bb61]"
              : "text-white",
          )}
        >
          <span className="text-lg">{navigationItem.name}</span>
          <span className="ml-1">
            <div
              className={classNames(
                "transform transition-transform duration-300",
                isShowSubItems ? "rotate-180" : "",
              )}
            >
              <IcChevronDownSVG className="h-3 w-3" />
            </div>
          </span>
        </span>

        {/* Sub Items */}
        <Transition
          as={React.Fragment}
          show={isShowSubItems}
          enter="transition duration-300 ease-out"
          enterFrom="transform opacity-0"
          enterTo="transform opacity-100"
          leave="transition duration-200 ease-out"
          leaveFrom="transform opacity-100"
          leaveTo="transform opacity-0"
        >
          <div className="absolute top-[100%] z-10">
            <div className="mt-2 w-[500px] space-y-4 rounded-lg bg-blue-900/50 p-2 shadow-xl">
              {navigationItem.subItems?.map((subItem, idx) => {
                return (
                  <WrapperLink
                    key={subItem.name + idx}
                    to={subItem.href}
                    isServerNavigation={subItem.isServerNavigation}
                    className="hover:bg-neutral-20 group flex flex-row items-center rounded-lg p-2"
                  >
                    {subItem.icon ? (
                      <div className="bg-neutral-20 group-hover:bg-neutral-0 flex h-8 w-8 items-center justify-center rounded">
                        {subItem.icon}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="ml-4 flex-1">
                      <div className="text-base font-medium text-white">
                        {subItem.name}
                      </div>
                      <div className="text-md mt-1 text-blue-100">
                        {subItem.description}
                      </div>
                    </div>
                  </WrapperLink>
                );
              })}
            </div>
          </div>
        </Transition>
      </span>
      {/* Backdrop */}
      <Transition
        as={React.Fragment}
        show={isShowSubItems}
        enter="transition duration-100 ease-out"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
      >
        <div className="fixed inset-0 top-0 z-[-1] !ml-0 backdrop-blur"></div>
      </Transition>

      {/* SEO Buttons */}
      <div className="hidden">
        {navigationItem.subItems?.map((subItem) => {
          return (
            <WrapperLink
              key={subItem.name}
              to={subItem.href}
              isServerNavigation={subItem.isServerNavigation}
            >
              <div>{subItem.icon}</div>
              <div>
                <div>{subItem.name}</div>
                <div>{subItem.description}</div>
              </div>
            </WrapperLink>
          );
        })}
      </div>
    </>
  );
};

const DesktopHeaderNavigationItem = ({
  navigationItem,
}: {
  navigationItem: RootNavigationItem;
}) => {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <WrapperLink
      key={navigationItem.name}
      to={navigationItem.href}
      isServerNavigation={navigationItem.isServerNavigation}
      className={`flex h-full items-center whitespace-nowrap break-keep hover:underline ${
        pathname === navigationItem.href
          ? "border-earth-green-40 font-medium text-[#d8bb61]"
          : "text-white"
      }`}
    >
      {navigationItem.name}
    </WrapperLink>
  );
};

function DesktopHeaderNavigation({ navigation }: Props) {
  return (
    <div className="relative hidden items-center space-x-4 text-white md:flex">
      {navigation.map((item, idx) => {
        if ("subItems" in item) {
          return (
            <DesktopHeaderNavigationItemWithSubItems
              key={item.name + String(idx)}
              navigationItem={item}
            />
          );
        }
        return (
          <DesktopHeaderNavigationItem
            key={item.name + String(idx)}
            navigationItem={item}
          />
        );
      })}
    </div>
  );
}

export default DesktopHeaderNavigation;
