import StrukturOrganisasiContent from "../components/layouts/tentang/StrukturOrganisasiContent";

const StrukturOrganisasi = () => {
  return (
    <div>
      <StrukturOrganisasiContent />
    </div>
  );
};

export default StrukturOrganisasi;
