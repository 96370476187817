import TugasFungsiContent from "../components/layouts/tentang/TugasFungsiContent";

const TugasFungsi = () => {
  return (
    <div>
      <TugasFungsiContent />
    </div>
  );
};

export default TugasFungsi;
