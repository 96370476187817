import MediaReleaseContent from "../components/layouts/ncb_interpol/MediaReleaseContent";

const MediaRelease = () => {
  return (
    <div>
      <MediaReleaseContent />
    </div>
  );
};

export default MediaRelease;
