import { Link, useParams } from "react-router-dom";
import { newsHighlights } from "../../../data/newsData/newsHighlights";
import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import { Article } from "@/src/modules/homepage/definitions/types";
import dayjs from "../../../modules/shared/libs/dayjs";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import IcLinkSVG from "../../../modules/shared/component/IcLinkSVG";
import { useTheme } from "../../../modules/shared/context/ThemeContext/ThemeProviderContext";
import { Theme } from "../../../modules/shared/definitions/enum";
import PopoverDynamic from "../../../modules/shared/component/dialog/PopoverDynamic";
import copyToClipboard from "../../../modules/shared/utilities/copyToClipboard";

interface ImageDetail {
  image: string;
}

const NewsDetailContent: React.FC = () => {
  const theme = useTheme();
  const { id } = useParams<{ id: string }>();
  const [articlesDetail, setArticlesDetail] = useState<Article | null>(null);
  const [imagesDetail, setImagesDetail] = useState<ImageDetail[]>([]);

  useEffect(() => {
    const getArticles = async () => {
      const response = await axios.get(
        `https://divhubinter.polri.go.id/api/artikel/${id}`,
      );
      setArticlesDetail(response.data.artikel[0]);
      setImagesDetail(response.data.image);
    };

    getArticles();
  }, [id]);

  return (
    <div className="container phone-s:max-w-full laptop:w-11/12 mx-auto py-12">
      <div className="card bg-neutral shadow-xl">
        <div className="card-body">
          <div className="text-sm breadcrumbs" style={{ color: "#999" }}>
            <ul>
              <li>
                <Link to={"/berita"}>Berita</Link>
              </li>
              <li>News</li>
            </ul>
          </div>
          <h2 className="card-title text-4xl">
            {articlesDetail?.artikel_judul}
          </h2>
          <div className="text-sm text-white">
            {dayjs(articlesDetail?.artikel_tanggal).format("DD MMMM YYYY")}
          </div>
          <div className="flex items-center space-x-2 mb-6">
            <PopoverDynamic
              renderPopoverContent={() => (
                <div className="tooltip-headless font-medium rounded-lg absolute -top-[calc(100%+68px)] left-1 mt-2 max-w-[300px] border-2 border-black bg-white px-5 py-3 text-sm text-black">
                  Link berhasil disalin
                </div>
              )}
            >
              <IcLinkSVG
                fill={theme === Theme.DARK ? "#FFF" : "#000"}
                className="w-6 h-6 cursor-pointer"
                onClick={() => copyToClipboard(window.location.href)}
              />
            </PopoverDynamic>

            <FacebookShareButton url={window.location.href}>
              <FacebookIcon size={24} />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
              <XIcon size={24} />
            </TwitterShareButton>
            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon size={24} />
            </WhatsappShareButton>
          </div>
          <div className="flex items-center object-contain justify-center mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl">
            <Carousel
              showArrows={true}
              showIndicators={true}
              infiniteLoop={false}
              dynamicHeight={false}
              autoPlay={false}
              showStatus={false}
              showThumbs={false}
            >
              {imagesDetail.length > 0
                ? imagesDetail.map((image, index) => (
                    <div key={index}>
                      <div className="flex relative bg-cover">
                        <img
                          src={`https://divhubinter.polri.go.id${image.image}`}
                          alt=""
                          className="laptop:w-full aspect-[16/9] rounded-xl object-cover"
                          loading="lazy"
                          style={{ pointerEvents: "auto" }}
                        />
                      </div>
                    </div>
                  ))
                : [
                    <div key="thumbnail">
                      <div className="flex relative bg-cover">
                        {articlesDetail?.artikel_thumbnail && (
                          <img
                            src={`https://divhubinter.polri.go.id${articlesDetail.artikel_thumbnail}`}
                            alt="Thumbnail"
                            className="w-full aspect-[16/9] rounded-xl object-cover"
                            loading="lazy"
                            style={{ pointerEvents: "auto" }}
                          />
                        )}
                      </div>
                    </div>,
                  ]}
            </Carousel>
          </div>
          <div
            className="text-justify mt-6"
            dangerouslySetInnerHTML={{
              __html: articlesDetail?.artikel_konten || "",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetailContent;
