import { Link } from "react-router-dom";

function Card08(props) {
    const { UUHukumHighlights } = props;
    return (
        <div>
            <div className="laptop:grid laptop:grid-cols-3 gap-8">
                {UUHukumHighlights.map((UUHukumItem, index) => (
                <div key={index}>
                    <div className="card card-compact h-full bg-neutral text-neutral-content shadow-xl">
                    <figure className="flex items-center p-8">
                        <img 
                        src={UUHukumItem.uu_thumbnail ? `https://divhubinter.polri.go.id/` + UUHukumItem.uu_thumbnail : "/dhi.png"}
                        alt="Null" 
                        className="h-48 w-48 object-contain"
                        />
                    </figure>
                    <div className="card-body justify-between">
                        <h3>{UUHukumItem.uu_kategori}</h3>
                        <h2 className="card-title">
                        <Link to={"/uudetail/" + UUHukumItem.uu_id}>
                            {UUHukumItem.uu_judul}
                        </Link>
                        </h2>
                        <div className="flex w-full">
                        <Link to={"/uudetail/" + UUHukumItem.uu_id}>
                            <button className="btn btn-primary">View</button>
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    );
}

export default Card08;
