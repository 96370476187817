import { Link, useParams } from "react-router-dom";
import { newsHighlights } from "../../../data/newsData/newsHighlights";
import React, { useState, StrictMode } from "react";
import Carousel from "react-simply-carousel";
import { carouselData } from "../../../data/carouseIData";
import ReactSimplyCarousel from "react-simply-carousel";
import axios from "axios";
import { useEffect } from "react";

const UuDetailContent = (props) => {
  const { id } = useParams();
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const [uuDetail, setUuDetail] = useState([]);

  const pdfUrl = `https://divhubinter.polri.go.id${uuDetail.uu_attch}`;

  // const [articles, setArticles] = useState([]);
  useEffect(() => {
    const getUuDetail = async () => {
      const response = await axios.get(
        "https://divhubinter.polri.go.id/api/uuh/" + id,
      );
      setUuDetail(response.data.uuhukum[0]);
    };

    getUuDetail();
  }, []);

  return (
    <div className="container phone-s:max-w-full laptop:w-11/12 mx-auto py-12">
      <div className="card bg-neutral shadow-xl">
        <div className="card-body">
          <div className="text-sm breadcrumbs " style={{ color: "#999" }}>
            <ul>
              <li>NCB Interpol</li>
              <li>
                <Link to={"/uu-hukum"}>UU & Hukum</Link>
              </li>
            </ul>
          </div>
          <h2 className="card-title text-4xl mb-6">{uuDetail.uu_judul}</h2>
          <div className="w-full flex justify-center">
            <object
              data={pdfUrl}
              type="application/pdf"
              className="h-screen w-full"
            >
              <p>
                It appears your browser does not support embedded PDFs.
                <br />
                You can download the PDF{" "}
                <a href={pdfUrl} rel="noreferrer" target="_blank">
                  here
                </a>
                .
              </p>
            </object>
          </div>

          {/* <p className="text-justify">{articlesDetail.artikel_konten}</p> */}
          {/* <div className="text-justify mt-6"
            dangerouslySetInnerHTML={{
            __html: articlesDetail.artikel_konten,
            }}
        ></div> */}
        </div>
      </div>
    </div>
  );
};

export default UuDetailContent;
