import React from "react";
import IcYoutubeSVG from "../IcYoutubeSVG";
import IcInstagramSVG from "../IcInstagramSVG";
import IcXTwitterSVG from "../IcXTwitterSVG";

type SocialMediaProps = {
  children?: React.ReactNode;
};

const SocialMedia: React.FC<SocialMediaProps> = () => {
  return (
    <div data-testid="SocialMedia" className="flex items-center space-x-4 ">
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.youtube.com/channel/UCAbgLacTIi0BmRhMmJiAYRQ"
        className="flex"
      >
        <IcYoutubeSVG className="h-8 w-8 text-white" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/divhubinterpolriofficial"
        className="flex"
      >
        <IcInstagramSVG className="h-8 w-8 text-white" />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://x.com/Divhubinter86"
        className="flex"
      >
        <IcXTwitterSVG className="h-8 w-8 text-white" />
      </a>
    </div>
  );
};

export default SocialMedia;
