import Dashboard from "../components/layouts/home/Dashboard";
import HomeHero from "../modules/homepage/component/HomeHero";

const Home = () => {
  return (
    <div className="flex flex-col ">
      <div className="relative">
        <HomeHero />
      </div>
      <Dashboard />
    </div>
  );
};

export default Home;
