import ProfilNCBContent from "../components/layouts/tentang/ProfilNCBContent";

const ProfilNCB = () => {
  return (
    <div>
      <ProfilNCBContent />
    </div>
  );
};

export default ProfilNCB;
