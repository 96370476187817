import * as React from "react";
import { SVGProps } from "react";
const IcLinkSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100.000000 100.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
      fill={props.fill || "#000000"}
      stroke="none"
    >
      <path d="M665 901 c-51 -24 -169 -145 -190 -193 -16 -40 -20 -104 -7 -112 11 -7 65 50 85 89 21 41 129 143 161 151 74 18 140 -48 122 -122 -8 -32 -110 -140 -151 -161 -39 -20 -96 -74 -89 -85 8 -13 72 -9 112 7 48 21 169 139 193 190 24 51 24 99 0 150 -22 48 -44 69 -94 90 -48 20 -93 19 -142 -4z" />
      <path d="M472 527 c-72 -73 -132 -139 -132 -148 0 -19 20 -39 40 -39 20 0 280 260 280 281 0 19 -20 39 -40 39 -8 0 -75 -60 -148 -133z" />
      <path d="M285 521 c-51 -24 -169 -145 -190 -193 -20 -49 -19 -94 4 -143 22 -48 44 -69 94 -90 81 -34 143 -12 241 83 37 37 77 85 87 107 19 41 25 110 11 119 -11 7 -65 -50 -85 -89 -21 -41 -129 -143 -161 -151 -74 -18 -140 48 -122 122 8 32 110 140 151 161 39 20 96 74 89 85 -9 14 -78 8 -119 -11z" />
    </g>
  </svg>
);
export default IcLinkSVG;
