import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Card01 from "./Card/Card01";
import React from "react";
import useQueryDhiHome from "../hooks/useQueryDhiHome";
import Card05 from "../../../components/layouts/home/Card/Card05";

const ArticleHighlightSection = () => {
  const { data } = useQueryDhiHome();
  const headlineArticles = data?.pages.flatMap((page) => page.headline);
  if (!headlineArticles) return null;
  return (
    <div className="card w-full bg-neutral text-neutral-content p-6">
      <div className="flex justify-left items-center">
        <FontAwesomeIcon
          icon={["fas", "fire"]}
          className="text-red-400 w-5 h-5"
        />

        <h2 className="text-2xl font-bold pl-2">News Highlights</h2>
      </div>

      <hr className="divider"></hr>

      <Card05 articles={headlineArticles} />
    </div>
  );
};

export default ArticleHighlightSection;
