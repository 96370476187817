import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Popover, Transition } from "@headlessui/react";
import { classNames } from "../../utilities/common";
type PopoverDynamicProps = {
  children?: React.ReactNode;
  renderPopoverContent?: ({ close }: { close: () => void }) => React.ReactNode;
  className?: string;
  isRenderOnHover?: boolean;
};

/**
 * PopoverDynamic will render a Popover component on desktop and BottomSheet component on mobile
 */
const PopoverDynamic: React.FC<PopoverDynamicProps> = ({
  children,
  renderPopoverContent,
  className,
  isRenderOnHover,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);
  const handleOpenPopover = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClosePopover = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const handleRenderPopover = useCallback(() => {
    return (
      <Popover>
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            static
            className="absolute inset-0 -left-5 bottom-[200px] z-30 w-[520px] shrink-0 transform bg-white"
          >
            {renderPopoverContent?.({
              close: () => setOpen(false),
            })}
          </Popover.Panel>
        </Transition>
      </Popover>
    );
  }, [isOpen, renderPopoverContent]);

  return (
    <>
      <div
        ref={ref}
        data-testid="PopoverDynamic"
        className={classNames("relative cursor-pointer", className ?? "")}
        onClick={!isRenderOnHover ? handleOpenPopover : undefined}
        onMouseEnter={isRenderOnHover ? handleOpenPopover : undefined}
        onMouseLeave={isRenderOnHover ? handleClosePopover : undefined}
      >
        {handleRenderPopover()}
        {children}
      </div>
    </>
  );
};

export default PopoverDynamic;
