import Card11 from "../home/Card/Card11";
import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
// import "../JahatTrans/pagination.css";

const PendidikanContent = () => {
  let { search } = useParams();
  const [items, setItems] = useState([]);
  const [urlpath, setUrlpath] = useState([]);
  const [pageCount, setpageCount] = useState(0);

  // const url = `https://divhubinter.polri.go.id/api/view_pendidikan?page=1`;

  //
  // const getComments = async () => {
  //     const res = await axios.get(url);

  //     // const total = res.headers.get("x-total-count");
  //     setpageCount(
  //     Math.ceil(res.data.pendidikan.total / res.data.pendidikan.per_page)
  //     );
  //     //
  //     //
  //     setUrlpath(res.data.pendidikan.path);
  //     setItems(res.data.pendidikan.data);
  // };

  // getComments();

  useEffect(() => {
    if (search === undefined) {
      const url = `https://divhubinter.polri.go.id/api/view_pendidikan?page=1`;

      const getComments = async () => {
        const res = await axios.get(url);

        // const total = res.headers.get("x-total-count");
        setpageCount(
          Math.ceil(res.data.pendidikan.total / res.data.pendidikan.per_page),
        );
        setUrlpath(res.data.pendidikan.path);
        setItems(res.data.pendidikan.data);
      };

      getComments();
    } else {
      const url = `https://divhubinter.polri.go.id/api/dhi_search/` + search;

      const getComments = async () => {
        const res = await axios.get(url);
        // const total = res.headers.get("x-total-count");
        setpageCount(Math.ceil(res.data.total / res.data.per_page));
      };

      //
      // const getComments = async () => {
      //     const res = await axios.get(url);

      //     // const total = res.headers.get("x-total-count");
      //     setpageCount(Math.ceil(res.data.total / res.data.per_page));
      //     //
      //
      //     setUrlpath(res.data.path);
      //     setItems(res.data.data);
      // };

      getComments();
    }
  }, []);

  const fetchComments = async (currentPage) => {
    //

    const url = urlpath + `?page=${currentPage}`;

    const res = await axios.get(url);
    if (search === undefined) {
      const data = await res.data.pendidikan.data;
      setItems(data);

      return data;
    } else {
      const data = await res.data.data;
      setItems(data);

      return data;
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchComments(currentPage);

    setItems(commentsFormServer);
  };
  return (
    // <div>
    //     <div className="mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl ">
    //         <div className="laptop:grid py-12">
    //         <h2 className="text-4xl font-bold text-left uppercase pb-8">Pendidikan</h2>
    //         <Card11 PendidikanHighlights={items} />
    //         <br />
    //         <br />
    //         <ReactPaginate
    //         className={"flex justify-center space-x-2"}
    //         previousLabel="&laquo;"
    //         nextLabel="&raquo;"
    //         breakLabel={"..."}
    //         pageCount={pageCount}
    //         marginPagesDisplayed={2}
    //         pageRangeDisplayed={3}
    //         onPageChange={handlePageClick}
    //         containerClassName={"btn-group "}
    //         pageClassName={"btn rounded-none "}
    //         previousClassName={"join-item btn btn-outline "}
    //         nextClassName={"join-item btn btn-outline"}
    //         breakClassName={"btn btn-disabled"}
    //         activeClassName={"btn-active"}
    //         />
    //         </div>
    //     </div>
    // </div>
    <div>
      <div className="mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl px-12 py-8">
        <h2 className="text-4xl font-bold pb-4">Pendidikan</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-dark">
            <thead className="bg-neutral">
              <tr>
                <th className="px-5 py-2 uppercase tracking-wider text-left">
                  KATEGORI
                </th>
                <th className="px-5 py-2 uppercase tracking-wider text-left">
                  PROGRAM
                </th>
                <th className="px-5 py-2 uppercase tracking-wider text-left">
                  TANGGAL
                </th>
                <th className="px-5 py-2 uppercase tracking-wider text-left">
                  SPONSOR
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-dark">
              {items.map((item) => (
                <tr key={item.lo_id}>
                  <td className="px-5 py-2 whitespace-normal">
                    {item.pendidikan_kategori}
                  </td>
                  <td className="px-5 py-2 whitespace-normal">
                    {item.pendidikan_program}
                  </td>
                  <td className="px-5 py-2 whitespace-normal">
                    {item.pendidikan_tanggal}
                  </td>
                  <td className="px-5 py-2 whitespace-normal">
                    {item.pendidikan_sponsor}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          className={"flex justify-center space-x-2 mt-8"}
          previousLabel="&laquo;"
          nextLabel="&raquo;"
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"btn-group "}
          pageClassName={"btn rounded-none "}
          previousClassName={"join-item btn btn-outline "}
          nextClassName={"join-item btn btn-outline"}
          breakClassName={"btn btn-disabled"}
          activeClassName={"btn-active"}
        />
      </div>
    </div>
  );
};

export default PendidikanContent;
