import React from "react";

type BaseNavigationItem = {
  name: string;
  /**
   * icon will only be rendered in mobile navigation
   */
  icon?: React.ReactNode;
};

type SubItemNavigationItem = {
  name: string;
  description?: string;
  icon?: React.ReactNode;
  href: string;
  isServerNavigation?: boolean;
};

export type RootNavigationItem = BaseNavigationItem & {
  href: string;
  isServerNavigation?: boolean;
};

export type NavigationItemWithSubItems = BaseNavigationItem & {
  subItems: SubItemNavigationItem[];
};

export type NavigationItem = RootNavigationItem | NavigationItemWithSubItems;

export const navigation: NavigationItem[] = [
  {
    name: "Beranda",
    href: "/",
  },
  {
    name: "Tentang",
    subItems: [
      {
        name: "Visi & Misi",
        href: "/visi-misi",
      },
      {
        name: "Profil",
        href: "/profil",
      },
      {
        name: "Struktur Organisasi",
        href: "/struktur-organisasi",
      },
      {
        name: "Tugas & Fungsi",
        href: "/tugas-fungsi",
      },
      {
        name: "ATPOL & STP",
        href: "/atpol-stp",
      },
      {
        name: "LO Asing",
        href: "/lo-asing",
      },
      {
        name: "Tim Koordinasi Interpol",
        href: "/tim-koordinasi",
      },
    ],
  },
  {
    name: "NCB Interpol",
    subItems: [
      {
        name: "Profil NCB Interpol",
        href: "/profil-ncb",
      },
      {
        name: "Kejahatan Transnasional",
        href: "/kejahatan-transnasional",
      },
      {
        name: "UU & Hukum",
        href: "/uu-hukum",
      },
      {
        name: "Media Release",
        href: "/media-release",
      },
    ],
  },
  {
    name: "Misi Internasional",
    subItems: [
      {
        name: "Profil Misi Internasional",
        href: "/profil-misinter",
      },
      {
        name: "Misi PBB",
        href: "/misi-pbb",
      },
      {
        name: "Pendidikan",
        href: "/pendidikan",
      },
      {
        name: "Pelatihan Personil",
        href: "/pelatihan-personil",
      },
    ],
  },
  {
    name: "Berita",
    href: "/berita",
  },
  {
    name: "Event",
    href: "/event",
  },
  {
    name: "Kontak",
    href: "/kontak",
  },
  {
    name: "Webmail",
    href: "https://interpol.go.id/webmail",
  },
];
