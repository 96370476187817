import { useInfiniteQuery } from "@tanstack/react-query";
import getDhiHome from "../axios/getDhiHome";

const useQueryDhiHome = () => {
  return useInfiniteQuery(
    ["dhi_home"],
    ({ pageParam = 1 }) => {
      return getDhiHome({ page: pageParam });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const beritaArticleList = allPages.flatMap((page) => page.berita.data);
        if (beritaArticleList.length < lastPage.berita.total) {
          return lastPage.berita.current_page + 1;
        }
        return undefined;
      },
    },
  );
};

export default useQueryDhiHome;
