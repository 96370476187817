import TimKoordinasiContent from "../components/layouts/tentang/TimKoordinasiContent";

const TimKoordinasi = () => {
  return (
    <div>
      <TimKoordinasiContent />
    </div>
  );
};

export default TimKoordinasi;
