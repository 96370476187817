import KejahatanTransnasionalContent from "../components/layouts/ncb_interpol/KejahatanTransnasionalContent";

const KejahatanTransnasional = () => {
  return (
    <div>
      <KejahatanTransnasionalContent />
    </div>
  );
};

export default KejahatanTransnasional;
