import Card09 from "../home/Card/Card09";
import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";
// import "../JahatTrans/pagination.css";

const MediaReleaseContent = () => {
  let { search } = useParams();
  const [items, setItems] = useState([]);
  const [urlpath, setUrlpath] = useState([]);
  //
  const [pageCount, setpageCount] = useState(0);

  useEffect(() => {
    if (search === undefined) {
      const url = `https://divhubinter.polri.go.id/api/dhi_view_media?page=1`;

      const getComments = async () => {
        const res = await axios.get(url);

        // const total = res.headers.get("x-total-count");
        setpageCount(
          Math.ceil(
            res.data.media_release.total / res.data.media_release.per_page,
          ),
        );
        //
        //
        setUrlpath(res.data.media_release.path);
        setItems(res.data.media_release.data);
      };

      getComments();
    } else {
      const url = `https://divhubinter.polri.go.id/api/dhi_search/` + search;

      const getComments = async () => {
        const res = await axios.get(url);

        // const total = res.headers.get("x-total-count");
        setpageCount(Math.ceil(res.data.total / res.data.per_page));
        //

        setUrlpath(res.data.path);
        setItems(res.data.data);
      };

      getComments();
    }
  }, []);

  const fetchComments = async (currentPage) => {
    //

    const url = urlpath + `?page=${currentPage}`;

    const res = await axios.get(url);
    if (search === undefined) {
      const data = await res.data.media_release.data;
      setItems(data);

      return data;
    } else {
      const data = await res.data.data;
      setItems(data);

      return data;
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;

    const commentsFormServer = await fetchComments(currentPage);

    setItems(commentsFormServer);
  };
  return (
    <div>
      <div className="mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl ">
        <div className="laptop:grid py-12">
          <h2 className="text-4xl font-bold text-left uppercase pb-8">
            Media Release
          </h2>
          <Card09 MediaReleaseHighlights={items} />
          <br />
          <br />
          <ReactPaginate
            className={"flex justify-center space-x-2"}
            previousLabel="&laquo;"
            nextLabel="&raquo;"
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"btn-group "}
            pageClassName={"btn rounded-none "}
            previousClassName={"join-item btn btn-outline "}
            nextClassName={"join-item btn btn-outline"}
            breakClassName={"btn btn-disabled"}
            activeClassName={"btn-active"}
          />
        </div>
      </div>
    </div>
  );
};

export default MediaReleaseContent;
