import KontakContent from "../components/layouts/kontak/KontakContent";

const Kontak = () => {
  return (
    <div>
      <KontakContent />
    </div>
  );
};

export default Kontak;
