import React, { useEffect } from "react";
import {
  NavigationItem,
  NavigationItemWithSubItems,
  RootNavigationItem,
} from "./NavigationConfig";
import { Transition } from "@headlessui/react";
import { classNames } from "../../utilities/common";
import MenuItem from "../MenuItem";
import IcChevronDownSVG from "../IcChevronDownSVG";

type MobileHeaderNavigationProps = {
  navigation: NavigationItem[];
};

const MobileHeaderNavigationItem = ({
  navigationItem,
}: {
  navigationItem: RootNavigationItem;
}) => {
  return (
    <MenuItem
      label={navigationItem.name}
      link={navigationItem.href}
      icon={navigationItem.icon}
    />
  );
};

const MobileHeaderNavigationItemWithSubItems = ({
  navigationItem,
}: {
  navigationItem: NavigationItemWithSubItems;
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isShowSubItems, setIsShowSubItems] = React.useState(false);

  useEffect(() => {
    // if user click outside navigation item, close sub items
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsShowSubItems(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={ref}>
      <div
        className={classNames(
          "flex cursor-pointer flex-row items-center py-[18px]",
          isShowSubItems ? "text-earth-green-60" : "",
        )}
        onClick={() => setIsShowSubItems(!isShowSubItems)}
      >
        {navigationItem.icon}
        <div
          className={classNames(
            navigationItem.icon ? "ml-4" : "",
            "flex-1 text-white",
          )}
        >
          {navigationItem.name}
        </div>
        <div className="ml-4 flex h-4 w-4 items-center justify-center">
          <div
            className={classNames(
              "transform transition-transform duration-300",
              isShowSubItems ? "rotate-180" : "",
            )}
          >
            <IcChevronDownSVG width={16} height={16} />
          </div>
        </div>
      </div>
      <Transition
        show={isShowSubItems}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 transform translate-y-0"
        enterTo="opacity-100 transform translate-y-1"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 transform translate-y-1"
        leaveTo="opacity-0 transform translate-y-0"
      >
        <div className="divide-y divide-neutral-40 border-t border-t-neutral-40 pl-4">
          {navigationItem.subItems.map((subItem, idx) => {
            return (
              <MenuItem
                key={idx}
                label={subItem.name}
                description={subItem.description}
                link={subItem.href}
                icon={subItem.icon}
              />
            );
          })}
        </div>
      </Transition>
    </div>
  );
};

const MobileHeaderNavigation: React.FC<MobileHeaderNavigationProps> = ({
  navigation,
}) => {
  return (
    <div
      data-testid="MobileHeaderNavigation"
      className="divide-y divide-neutral-40"
    >
      {navigation.map((item, idx) => {
        if ("subItems" in item) {
          return (
            <MobileHeaderNavigationItemWithSubItems
              key={String(idx)}
              navigationItem={item}
            />
          );
        }
        return (
          <MobileHeaderNavigationItem key={String(idx)} navigationItem={item} />
        );
      })}
    </div>
  );
};

export default MobileHeaderNavigation;
