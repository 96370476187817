import LOAsingContent from "../components/layouts/tentang/LOAsingContent";

const LOAsing = () => {
  return (
    <div>
      <LOAsingContent />
    </div>
  );
};

export default LOAsing;
