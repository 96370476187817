import AtpolSTPContent from "../components/layouts/tentang/AtpolSTPContent";

const AtpolSTP = () => {
  return (
    <div>
      <AtpolSTPContent />
    </div>
  );
};

export default AtpolSTP;
