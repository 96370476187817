import React from "react";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import MainHeader from "./MainHeader/MainHeader";
import { useWindowWidth } from "../hooks/useWindowWidth";

export type AppLayoutProps = {
  theme: string;
  toggleTheme: () => void;
};

const AppLayout: React.FC<AppLayoutProps> = ({ theme, toggleTheme }) => {
  const { isMdScreen } = useWindowWidth();
  const location = useLocation();
  const transparentRoutes = ["/"];
  const isTransparent = transparentRoutes.some(
    // we disable transparent on mobile
    (path) => path === location.pathname && isMdScreen,
  );

  return (
    <div>
      <MainHeader
        backgroundColorRGB="32, 77, 132"
        transparent={isTransparent}
        theme={theme}
        toggleTheme={toggleTheme}
      />
      <Outlet /> {/* This will render the matched child route component. */}
    </div>
  );
};

export default AppLayout;
