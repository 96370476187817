import React from "react";
import ReactPlayer from "../../shared/libs/react-player/ReactPlayer";
import InfiniteSlider, { Slide } from "../../shared/InfiniteSliders";
import useQueryDhiHome from "../hooks/useQueryDhiHome";
import { classNames } from "../../shared/utilities/common";
// const navigation = [
//   { name: "Product", href: "#" },
//   { name: "Features", href: "#" },
//   { name: "Marketplace", href: "#" },
//   { name: "Company", href: "#" },
// ];

type HomeHeroProps = {
  children?: React.ReactNode;
};

const HomeHero: React.FC<HomeHeroProps> = () => {
  const [isHideThumbnail, setHideThumbnail] = React.useState(false);
  const { data: dhiHomeData } = useQueryDhiHome();
  const beritaArticlesWithPagination = dhiHomeData?.pages.flatMap(
    (page) => page.berita,
  );
  const beritaArticles = (beritaArticlesWithPagination ?? []).flatMap(
    (page) => page.data,
  );
  const beritaSlidesWithLink = beritaArticles?.map((article) => (
    <a
      className="hover:underline cursor-pointer min-w-[100vw] whitespace-nowrap h-8 flex items-center justify-center flex-shrink-0 px-2 bg-[#204d84] flex-nowrap"
      target="_blank"
      rel="noreferrer"
      href={`/newsdetail/${article.artikel_id}`}
      key={article.artikel_id}
      onClick={() => {
        window.open(`/newsdetail/${article.artikel_id}`);
      }}
    >
      {article.artikel_judul}
    </a>
  ));
  const slides: Slide[] = beritaSlidesWithLink.map((slide) => ({
    number: slide,
  }));

  return (
    <div className="bg-gray-900" data-testid="HomeHero">
      <div className="relative isolate overflow-hidden">
        <div className="relative h-[600px] md:h-[100vh] overflow-hidden">
          <div
            className={classNames(
              "h-full w-full",
              isHideThumbnail ? "hidden" : "",
            )}
          >
            <img
              alt="thumbnail video"
              src="/cp_dhi_thumbnail.jpg"
              className="object-cover w-full h-full"
            />
          </div>
          <ReactPlayer
            controls={false}
            playing
            playsinline
            loop
            muted
            url="/cp_dhi.mp4"
            onReady={() => setHideThumbnail(true)}
            className="md:-mt-[72px] pointer-events-none absolute md:relative -left-[100vw] md:left-0 !w-[320vw] md:!w-[100vw]"
          />
          <div className="sticky md:absolute bottom-0 w-full z-20">
            <InfiniteSlider slides={slides} />
          </div>
        </div>
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="absolute inset-0 mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="mt-[5vh] flex flex-col items-center justify-center">
            <img
              src="/logo_dhi.png"
              alt="Logo Divhubinter Polri"
              className="mb-6 w-[200px] h-auto"
            />
            <h1 className="mb-2 text-center text-3xl font-medium text-white">
              DIVHUBINTER POLRI
            </h1>
            <p className="mb-4 text-center font-medium text-white">
              Divisi Hubungan Internasional Kepolisian Negara Republik Indonesia
            </p>
            <div className="h-[1px] w-full bg-gray-500"></div>
          </div>
          {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-400 ring-1 ring-white/10 hover:ring-white/20">
              Announcing our next round of funding.{" "}
              <a href="#" className="font-semibold text-white">
                <span className="absolute inset-0" aria-hidden="true" />
                Read more <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Data to enrich your online business
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
              lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat
              fugiat aliqua.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              >
                Get started
              </a>
              <a
                href="#"
                className="text-sm font-semibold leading-6 text-white"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div> */}
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
