import NewsLatestSection from "../home/NewsLatestSection";

const TugasFungsiContent = () => {
  return (
    <div>
      <div className="container mx-auto px-12">
        <div className="flex flex-row my-12">
          <div className="p-4">
            <div className="card w-full bg-neutral text-neutral-content shadow-xl border-t-8 border-blue-600 ... rounded-none">
              <div className="card-body p-0">
                <div className="flex flex-row px-6 pt-6">
                  <div className="grow">
                    <h2 className="card-title text-2xl font-bold">
                      Tugas & Fungsi
                    </h2>
                  </div>
                  <div className="shrink">
                    <div className="text-sm breadcrumbs">
                      <ul>
                        <li>Tentang</li>
                        <li>
                          <a href="/visi-misi">Tugas & Fungsi</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="divider m-0"></div>

                <div className="mb-8">
                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      Tugas Divisi Hubungan Internasional Polri:
                    </p>
                    <p className="indent-8 pt-4">
                      Divhubinter Polri bertugas menyelenggarakan kegiatan
                      National Central Bureau (NCB)-Interpol dalam upaya
                      penanggulangan kejahatan internasional/transnasional,
                      mengemban tugas misi internasional dalam misi damai
                      kemanusiaan dan pengembangan kemampuan sumber daya manusia
                      serta turut membantu pelaksanaan perlindungan hukum
                      terhadap Warga Negara Indonesia di Luar Negeri.
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="indent-8">
                      Lampiran X Perkap Nomor 6 Tahun 2017 tentang Susunan
                      Organisasi dan Tata Kerja pada tingkat Markas Besar
                      Kepolisian Negara Republik Indonesia halaman 379 s.d 380
                    </p>
                  </div>

                  <div className="mx-12 mb-8">
                    <p className="text-xl font-bold">
                      Fungsi Divisi Hubungan Internasional Polri:
                    </p>
                    <ul className="list-outside list-disc pl-8 ...">
                      <li>
                        perencanaan dan pembinaan kegiatan administrasi personel
                        dan logistik, ketatausahaan dan urusan dalam, pelayanan
                        keuangan, serta pengkajian strategis Divhubinter Polri
                        dalam kerangka kerja sama internasional;
                      </li>
                      <li>
                        penyiapan administrasi perjalanan dinas personel Polri
                        ke luar negeri dan pelaksanaan koordinasi protokoler
                        rangkaian kegiatan kunjungan dinas tamu VVIP dan anggota
                        organisasi internasional;
                      </li>
                      <li>
                        pelaksanaan kerja sama lintas sektoral dalam rangka
                        penanggulangan kejahatan Internasional/transnasional,
                        pertukaran informasi intelijen kriminal, pelayanan umum
                        internasional (International Public Service), bantuan
                        teknis dan taktis investigasi yang berkaitan dengan
                        Ekstradisi dan Mutual Legal Assistance (MLA);
                      </li>
                      <li>
                        pertukaran informasi tentang kejahatan
                        internasional/transnasional dan informasi lainnya
                        berkaitan dengan international event dan kerja sama
                        internasional melalui sistem jaringan komunikasi
                        INTERPOL, ASEANAPOL, DPKO (Department of Peacekeeping
                        Operations) dan sistem teknologi informasi lainnya;
                      </li>
                      <li>
                        pelaksanaan koordinasi dengan pihak terkait dan
                        memfasilitasi personel Polri yang dipersiapkan untuk
                        melaksanakan tugas misi perdamaian dan kemanusiaan;
                      </li>
                      <li>
                        pelaksanaan koordinasi dengan pihak terkait khususnya
                        pihak kepolisian negara akreditasi dan organisasi resmi
                        internasional (PBB, ICPO-Interpol) serta organisasi
                        internasional lainnya yang diakui dalam rangka
                        pengembangan sumber daya manusia dan logistik Polri;
                      </li>
                      <li>
                        pelaksanaan dan pembinaan Atase Polri, Senior Liaison
                        Officer(SLO), Staf Teknis Polri, dan Liaison Officer
                        (LO) serta personel Polri yang bertugas di luar negeri,
                        organisasi Internasional dan kantor kepolisian di negara
                        akreditasi;
                      </li>
                      <li>
                        pelaksanaan koordinasi dengan Atase Kepolisian negara
                        lain atau LO/penegak hukum negara lain di Indonesia
                        serta melakukan koordinasi dan komunikasi dengan
                        instansi terkait dalam rangka pengamanan dan penegakan
                        hukum di perbatasan
                      </li>
                      <li>
                        pelaksanaan hubungan kerja sama internasional di luar
                        negeri yang meliputi kerja sama di bidang kepolisian,
                        penegakan hukum dan perlindungan WNI di luar negeri.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TugasFungsiContent;
