import React, { useState, useEffect } from "react";

import "./fontawesome";
import Header from "./components/layouts/header/Header";
import Footer from "./components/layouts/footer/Footer";

import LoadingSpinner from "./components/spinner/LoadingSpinner";
import AppRouters from "./routers/Routers";
import { ThemeProviderContext } from "./modules/shared/context/ThemeContext/ThemeProviderContext";
import { Theme } from "./modules/shared/definitions/enum";

function Loading() {
  return (
    <div>
      <LoadingSpinner />
    </div>
  );
}

function App() {
  // Toggling Theme
  const [theme, setTheme] = useState<Theme>(() => {
    const savedTheme = localStorage.getItem("theme") as Theme;
    return savedTheme ? savedTheme : Theme.DARK;
  });

  useEffect(() => {
    const htmlEl = document.documentElement;
    htmlEl.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "darkTheme" ? Theme.LIGHT : Theme.DARK);
  };

  // Spinner
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 500); // simulate a 1.5-second loading time
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <ThemeProviderContext.Provider value={theme}>
          <div className="flex flex-col">
            {/* <Header theme={theme} toggleTheme={toggleTheme} /> */}
            <AppRouters theme={theme} toggleTheme={toggleTheme} />
            <Footer />
            {/* this div is only a trigger for creating tailwind classes to be added on css files */}
            <div className="hidden">
              <div className="!cursor-wait"></div>
            </div>
          </div>
        </ThemeProviderContext.Provider>
      )}
    </div>
  );
}

export default App;
