import ProfilContent from "../components/layouts/tentang/ProfilContent";

const Profil = () => {
  return (
    <div>
      <ProfilContent />
    </div>
  );
};

export default Profil;
