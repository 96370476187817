import PelatihanPersonilContent from "../components/layouts/misi_internasional/PelatihanPersonilContent";

const PelatihanPersonil = () => {
  return (
    <div>
      <PelatihanPersonilContent />
    </div>
  );
};

export default PelatihanPersonil;
