// Copy text to clipboard across browsers
export default function copyToClipboard(text?: string): void {
  if (!text) return;
  // Check if the Clipboard API is supported
  if (navigator.clipboard) {
    // Use the Clipboard API to write text to the clipboard
    navigator.clipboard.writeText(text).then(
      () => {
        // Success
      },
      (err: Error) => {
        // Error
        console.error("Failed to copy: ", err);
      },
    );
  } else {
    // Use the fallback method
    // Create a hidden textarea element
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "absolute";
    textarea.style.left = "-9999px";
    document.body.appendChild(textarea);
    // Select the textarea content
    textarea.select();
    // Try to execute the copy command
    try {
      const successful = document.execCommand("copy");
      if (successful) {
        // Success
      } else {
        // Error
      }
    } catch (err) {
      // Error
      console.error("Failed to copy: ", err);
    }
    // Remove the textarea element
    document.body.removeChild(textarea);
  }
}
